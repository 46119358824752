import { ToastContentProps } from 'react-toastify';

import styles from 'components/Logic/ToastMessage/components/Socials/Socials.module.scss';
import IcClose from '@/components/Icons/form/IcClose';
import IcVk from '@/components/Icons/general/IcVk';
import IcTg from '@/components/Icons/general/IcTg';

const Socials = ({ closeToast }: Partial<ToastContentProps>) => {
  const onMessageClose = () => {
    if (closeToast) closeToast();
  };

  return (
    <div className={styles.container}>
      <div className={styles.info}>
        <h3>Подпишитесь на нас</h3>
        <div className={styles.links}>
          <a href='https://vk.com/sakhipoteka' target='_blank' rel='noreferrer'>
            <IcVk />
            Вконтакте
          </a>
          <a href='https://t.me/sakhipoteka' target='_blank' rel='noreferrer'>
            <IcTg />
            Телеграм
          </a>
        </div>
      </div>
      <IcClose onClick={onMessageClose} className={styles.closeBtn} />
    </div>
  );
};

export default Socials;

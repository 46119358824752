import React, { FC, cloneElement, useEffect, useState } from 'react';

import clsx from 'clsx';

import { AccordionProps, AccordionSummaryProps } from './types';

import styles from './Accordion.module.scss';

const Accordion: FC<AccordionProps> = ({
  expanded = false,
  onChange,
  reverse = false,
  className = '',
  children,
  ...props
}) => {
  const [header, content] = children;
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(expanded);
  }, [expanded]);

  const clickOnAccordion = () => {
    setIsOpen((prev) => !prev);

    if (typeof onChange === 'function') {
      onChange(!isOpen);
    }
  };

  const accordionStyle = clsx({
    [styles.accordion]: true,
    [className]: className,
    [styles.reverse]: reverse === true,
  });

  return (
    <div {...props} className={accordionStyle} aria-expanded={isOpen}>
      {cloneElement(header, { onClick: clickOnAccordion })}
      {cloneElement(content, { isOpen: isOpen })}
    </div>
  );
};

export default Accordion;

import { FC, useEffect, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import clsx from 'clsx';

import { CheckElementAccess, Popover } from '@/components/Logic';
import { Button } from '@/components/UI';
import { useStore } from '@/hooks/useStore';

import { menuData } from '../Menu/menu.data';
import { staticLinks } from '@/config/routingLinks';

import { THeaderPopovers } from '@/types/general/unions';

import styles from './MobileMenu.module.scss';
import { useRouter } from 'next/router';
import IcHomeStorage from '@/components/Icons/IcHomeStorage';
import IcUser from '@/components/Icons/IcUser';
import IcHintGray from '@/components/Icons/IcHintGray';
import Link from 'next/link';
import IcLogout from '@/components/Icons/IcLogout';

interface MenuProps {
  isMobileMenuActive: boolean;
  setIsBurgerActive: (isBurgerActive: boolean) => void;
  currentTab: THeaderPopovers;
}

type HeaderPopover = 'rent' | 'sale' | 'mortgage' | 'additional';

const MobileMenu: FC<MenuProps> = ({ isMobileMenuActive, setIsBurgerActive }) => {
  const store = useStore();
  const [isHintActive, setIsHintActive] = useState(false);
  const [refHint, setRefHint] = useState<SVGSVGElement | null>(null);

  const router = useRouter();

  useEffect(() => {
    if (!isMobileMenuActive) {
      document.body.style.overflow = 'initial';
    } else {
      document.body.style.overflow = 'hidden';
    }
  }, [isMobileMenuActive]);

  const menuWrapperStyles: string = clsx({
    [styles.mobileMenuWrapper]: true,
    [styles.visible]: isMobileMenuActive,
  });
  const menuStyles: string = clsx({
    [styles.mobileMenu]: true,
    [styles.visible]: isMobileMenuActive,
  });
  const hintButtonStyles = clsx(styles.hintBtn, {
    [styles.active]: isHintActive,
  });

  const onLogout = () => {
    store.auth.logout();
    router.push(staticLinks.authorization);
    setIsBurgerActive(false);
  };

  useEffect(() => {
    document.getElementById('hint')?.parentElement?.classList.add(styles.hintContainer);
  }, [isHintActive]);

  const profileData = {
    categories: [
      {
        title: 'Редактировать профиль',
        icon: <IcUser />,
        path: staticLinks.editProfile,
      },
      {
        title: 'Мои объявления',
        icon: <IcHomeStorage />,
        path: staticLinks.personalAdverts,
      },
    ],
  };

  return (
    <div onClick={() => setIsBurgerActive(false)} className={menuWrapperStyles}>
      <div onClick={(event) => event.stopPropagation()} className={menuStyles}>
        <div className={styles.buttons}>
          <div className={styles.firstBtn}>
            <CheckElementAccess checkAuth={true}>
              <Link href={staticLinks.createAdvert} onClick={() => setIsBurgerActive(false)}>
                <Button theme='filled'>Разместить объявление</Button>
              </Link>
            </CheckElementAccess>
            <CheckElementAccess checkAuth={false}>
              <Link href={staticLinks.authorization} onClick={() => setIsBurgerActive(false)}>
                <Button theme='filled'>Разместить объявление</Button>
              </Link>
            </CheckElementAccess>

           {/*  <IcHintGray
              className={hintButtonStyles}
              ref={setRefHint}
              onClick={() => setIsHintActive(!isHintActive)}
            />
            <Popover
              isOpen={isHintActive}
              offset={[-30, -35]}
              onClose={() => setIsHintActive(false)}
              refEl={refHint}
              className={styles.hint}
            >
              <div></div>
              Бесплатно до 31.12.2023
            </Popover> */}
          </div>
          <CheckElementAccess checkAuth={false}>
            <Link href={staticLinks.authorization} onClick={() => setIsBurgerActive(false)}>
              <Button>Войти</Button>
            </Link>
          </CheckElementAccess>
        </div>

        <Accordion allowZeroExpanded={true} allowMultipleExpanded={true}>
          <CheckElementAccess checkAuth={true}>
            <AccordionItem key='profile' className={styles.profile}>
              <AccordionItemHeading>
                <AccordionItemButton className={styles.accordionButton}>
                  Профиль
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel className={styles.panel}>
                {profileData.categories.map((card) => {
                  return (
                    <Link
                      onClick={() => setIsBurgerActive(false)}
                      key={card.title}
                      href={card.path}
                      className={styles.accordionElement}
                    >
                      <i className={styles.icon}>{card.icon}</i>
                      <span>{card.title}</span>
                    </Link>
                  );
                })}

                <button onClick={onLogout} className={styles.accordionElement}>
                  <i className={styles.icon}>
                    <IcLogout />
                  </i>
                  <span>Выйти</span>
                </button>
              </AccordionItemPanel>
            </AccordionItem>
          </CheckElementAccess>

          {Object.keys(menuData).map((linkItem) => {
            if(linkItem === 'mortgage'){
              return (
                  <Link
                    onClick={() => setIsBurgerActive(false)}
                    key={linkItem}
                    href={staticLinks.mortgage}
                    className={styles.accordionLink}
                  >
                    <span>{menuData[linkItem as HeaderPopover].title}</span>
                  </Link>
               );
            }
            if(linkItem === 'horizont') {
              return (
                  <Link key={linkItem} href={staticLinks.horizont} className={styles.accordionLink}>
                    <span>{menuData[linkItem as HeaderPopover].title}</span>
                  </Link>
              );
            }
            else{
              return(<AccordionItem key={linkItem}>
                <AccordionItemHeading>
                  <AccordionItemButton className={styles.accordionButton}>
                    {menuData[linkItem as HeaderPopover].title}
                  </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel className={styles.panel}>
                  {menuData[linkItem as HeaderPopover].categories.map((card) => (
                    <Link
                      onClick={() => setIsBurgerActive(false)}
                      key={card.title}
                      href={card.path}
                      className={styles.accordionElement}
                    >
                      <i className={styles.icon}>{card.icon}</i>
                      <span>{card.title}</span>
                    </Link>
                  ))}
                </AccordionItemPanel>
              </AccordionItem>);
            }
          })}
        </Accordion>
      </div>
    </div>
  );
};

export default MobileMenu;

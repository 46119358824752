import makeRequest from '@/api/makeRequest';

import {
  GetAdvertResponse,
  GetAdvertsResponse,
  GetExternalAdvertsResponse,
  GetHomePageAdverts,
} from '@/types/api/IAdvertResponse';
import { TAdvertCU, TAdvertsIds, TExtAdvertsIds } from '@/types/entities/IAdvert';

class AdvertService {
  fetchAdvert(id: string) {
    return makeRequest<GetAdvertResponse>({
      url: `api/adverts/${id}`,
    });
  }

  fetchAdverts(query: string) {
    return makeRequest<GetAdvertsResponse>({
      url: `api/adverts${query}`,
    });
  }

  fetchExternalAdverts(search = '') {
    return makeRequest<GetExternalAdvertsResponse>({
      url: `api/external_adverts${search}`,
    });
  }

  fetchMapAdverts(advertsIds: TAdvertsIds, page: string) {
    return makeRequest<GetAdvertsResponse>({
      url: `api/map/get_adverts${page}`,
      data: advertsIds,
      method: 'POST',
    });
  }

  fetchMapExtAdverts(advertsIds: TExtAdvertsIds, page: string) {
    return makeRequest<GetExternalAdvertsResponse>({
      url: `api/map/get_adverts${page}`,
      data: advertsIds,
      method: 'POST',
    });
  }

  fetchHomePageRentAdverts() {
    return makeRequest<GetHomePageAdverts>({
      url: 'api/rent_adverts',
    });
  }

  fetchHomePageSaleAdverts() {
    return makeRequest<GetHomePageAdverts>({
      url: 'api/sale_adverts',
    });
  }

  createAdvert(advert: FormData) {
    return makeRequest<GetAdvertResponse>({
      url: 'api/adverts',
      authToken: true,
      method: 'POST',
      data: advert,
    });
  }

  updateAdvert(id: string, advert: TAdvertCU) {
    return makeRequest<GetAdvertResponse>({
      url: `api/adverts/${id}`,
      authToken: true,
      method: 'PUT',
      data: advert,
    });
  }

  deleteAdvert(id: number) {
    return makeRequest<void>({
      url: `api/adverts/${id}`,
      authToken: true,
      method: 'DELETE',
    });
  }

  updateFiles(advertId: string, files: FormData) {
    return makeRequest<GetAdvertResponse>({
      url: `api/adverts/${advertId}/files_update`,
      method: 'POST',
      data: files,
      authToken: true,
    });
  }

  updatePhotoDescription(advertId: string, photoId: number, description: string) {
    return makeRequest<GetAdvertResponse>({
      url: `api/adverts/${advertId}/photos/${photoId}/description_update`,
      method: 'PUT',
      data: { description },
      authToken: true,
    });
  }

  updateMainPhoto(advertId: string, photoId: number) {
    return makeRequest<GetAdvertResponse>({
      url: `api/adverts/${advertId}/photos/main_update`,
      method: 'PUT',
      data: { main_photo_id: photoId },
      authToken: true,
    });
  }

  rotatePhoto(advertId: string, photoId: number, rotate: number) {
    return makeRequest<GetAdvertResponse>({
      url: `api/adverts/${advertId}/photos/${photoId}/rotate`,
      method: 'PUT',
      data: { rotate: rotate },
      authToken: true,
    });
  }

  deletePhoto(advertId: string, photoId: number) {
    return makeRequest<void>({
      url: `api/adverts/${advertId}/photos/${photoId}`,
      method: 'DELETE',
      authToken: true,
    });
  }

  deleteFile(advertId: string, fileId: number) {
    return makeRequest<void>({
      url: `api/adverts/${advertId}/documents/${fileId}`,
      method: 'DELETE',
      authToken: true,
    });
  }
}

export default new AdvertService();

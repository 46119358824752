// @ts-nocheck
import { makeAutoObservable } from 'mobx';

import {
  TTradeInAdvertItemCamelize,
  TTradeInAdvertItemResponse,
} from '@/types/entities/ITradeInAdvert';
import { TAdvertPhotoCamelize, TAdvertPhotoResponse, TTradeInDeals } from '@/types/general/unions';

export class TradeInAdvertItem implements TTradeInAdvertItemCamelize {
  id: number;
  dealType: TTradeInDeals;
  totalArea: string;
  roomsCount: number;
  floor: number;
  totalFloors: number;
  price: number;
  photo: TAdvertPhotoCamelize | null;
  createdAt: string;
  updatedAt: string;

  constructor(item?: TTradeInAdvertItemResponse) {
    if (item) {
      this.id = item.id;
      this.dealType = item.deal_type;
      this.totalArea = item.total_area;
      this.roomsCount = item.rooms_count;
      this.floor = item.floor;
      this.totalFloors = item.total_floors;
      this.price = item.price;
      this.photo = item.photo ? this.serializePhoto(item.photo) : null;
      this.createdAt = item.created_at;
      this.updatedAt = item.updated_at;
    }
    makeAutoObservable(this);
  }

  serializePhoto(photo: TAdvertPhotoResponse): TAdvertPhotoCamelize {
    return {
      id: photo.id,
      description: photo.description,
      url: photo.url,
      isMain: photo.is_main,
      thumbUrl: photo.thumb_url,
    };
  }
}

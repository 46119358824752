import { FC } from 'react';
import { useLocation } from 'react-router';

import { observer } from 'mobx-react-lite';

import { useStore } from '@/hooks/useStore';

import { CheckElementAccessProps } from './types';

const CheckElementAccess: FC<CheckElementAccessProps> = observer(
  ({ children, checkAuth = null, checkOwner = null, checkExcepted = null }) => {
    const store = useStore();
    const isAuth = store.auth.isAuth;
    const accountId = store.account?.profile.id;

    if (checkAuth !== null && (isAuth === null || checkAuth !== isAuth)) return null;

    if (checkOwner !== null && (accountId === null || checkOwner?.id !== accountId)) return null;

    if (checkExcepted !== null && checkExcepted.id === accountId) return null;

    return children;
  },
);

export default CheckElementAccess;

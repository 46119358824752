import { FC } from 'react';

import clsx from 'clsx';

import styles from './SeeAllLink.module.scss';

interface SeeAllProps {
  path: string;
  mobileNone?: boolean;
  desktopNone?: boolean;
}

const SeeAllLink: FC<SeeAllProps> = ({ path, mobileNone, desktopNone }) => {
  const linkStyles: string = clsx({
    [styles.seeAllLink]: true,
    [styles.mobileNone]: mobileNone,
    [styles.desktopNone]: desktopNone,
  });

  return (
    <a className={linkStyles} href={path}>
      Смотреть все
    </a>
  );
};

export default SeeAllLink;

import styles from './NewsCardSkeleton.module.scss';

const NewsCardSkeleton = () => {
  return (
    <li className={styles.item}>
      <div className={styles.image}></div>
      <div className={styles.content}>
        <div className={styles.date}></div>
        <div className={styles.title}></div>
        <div className={styles.newsContent}></div>
      </div>
    </li>
  );
};

export default NewsCardSkeleton;

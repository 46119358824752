import { FC } from 'react';

import { TIcon } from './TIcon/TIcon';

const IcInWork: FC<TIcon> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width='168'
      height='168'
      viewBox='0 0 168 168'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g opacity='0.5'>
        <path
          d='M134.19 83.9999L102.13 51.8699L112 41.9999L154 83.9999L128.87 109.13L119 99.1899L134.19 83.9999ZM9.73047 29.5399L39.0605 58.8699L14.0005 83.9999L56.0005 126L65.8705 116.13L33.8105 83.9999L49.0005 68.8099L138.46 158.27L148.33 148.4L19.6705 19.6699L9.73047 29.5399Z'
          fill='#53B374'
        />
      </g>
    </svg>
  );
};

export default IcInWork;

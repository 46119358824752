import { FC } from 'react';

import { TIcon } from '../TIcon/TIcon';

const IcHomePerson: FC<TIcon> = ({ className, onClick }) => {
  return (
    <svg
      onClick={onClick}
      className={className}
      width='49'
      height='48'
      viewBox='0 0 49 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_6734_49736)'>
        <path
          d='M12.333 38H18.333H30.333H36.333V20L24.333 11L12.333 20V38ZM8.33301 42V18L24.333 6L40.333 18V42H26.333H22.333H8.33301Z'
          fill='#53B374'
        />
        <g clipPath='url(#clip1_6734_49736)'>
          <path d='M25.333 14L44.333 -2V38H25.333V14Z' fill='white' />
          <path
            d='M42 32.2941V28.2353C41.9989 27.1592 41.5772 26.1274 40.8273 25.3665C40.0773 24.6055 39.0605 24.1775 38 24.1765H30C28.9395 24.1775 27.9227 24.6055 27.1727 25.3665C26.4228 26.1274 26.0011 27.1592 26 28.2353V32.2941V35H42V32.2941ZM28.6667 28.2353C28.6667 27.8765 28.8071 27.5323 29.0572 27.2786C29.3072 27.0249 29.6464 26.8824 30 26.8824H38C38.3536 26.8824 38.6928 27.0249 38.9428 27.2786C39.1929 27.5323 39.3333 27.8765 39.3333 28.2353V32.2941H28.6667V28.2353ZM28.6667 17.4118C28.6667 16.3414 28.9795 15.2951 29.5655 14.4052C30.1515 13.5152 30.9845 12.8216 31.959 12.4119C32.9336 12.0023 34.0059 11.8952 35.0405 12.104C36.075 12.3128 37.0254 12.8282 37.7712 13.5851C38.5171 14.3419 39.0251 15.3062 39.2309 16.356C39.4366 17.4058 39.331 18.4939 38.9274 19.4828C38.5237 20.4716 37.8401 21.3168 36.963 21.9115C36.086 22.5061 35.0548 22.8235 34 22.8235C32.5859 22.8221 31.2302 22.2515 30.2303 21.2369C29.2304 20.2223 28.6681 18.8466 28.6667 17.4118ZM31.3333 17.4118C31.3333 17.9469 31.4897 18.4701 31.7827 18.9151C32.0758 19.3601 32.4922 19.7069 32.9795 19.9117C33.4668 20.1165 34.003 20.1701 34.5202 20.0657C35.0375 19.9612 35.5127 19.7035 35.8856 19.3251C36.2586 18.9467 36.5125 18.4645 36.6154 17.9397C36.7183 17.4148 36.6655 16.8707 36.4637 16.3763C36.2618 15.8818 35.9201 15.4592 35.4815 15.1619C35.043 14.8646 34.5274 14.7059 34 14.7059C33.293 14.7066 32.6151 14.9919 32.1152 15.4992C31.6152 16.0065 31.334 16.6943 31.3333 17.4118Z'
            fill='#53B374'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_6734_49736'>
          <rect width='48' height='48' fill='white' transform='translate(0.333008)' />
        </clipPath>
        <clipPath id='clip1_6734_49736'>
          <rect width='48' height='48' fill='white' transform='translate(0.333008)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IcHomePerson;

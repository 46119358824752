import { FC } from 'react';

type TIcon = {
  className?: string;
  onClick?: VoidFunction;
};
const IcHomeUp: FC<TIcon> = ({ onClick, className }) => {
  return (
    <svg width='48' height='48' viewBox='0 0 48 48' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g clipPath='url(#clip0_3483_20972)'>
        <path
          d='M12 38H18H30H36V20L24 11L12 20V38ZM8 42V18L24 6L40 18V42H26H22H8Z'
          fill='#53B374'
        />
        <g clipPath='url(#clip1_3483_20972)'>
          <path d='M25 14L44 -2V38H25V14Z' fill='white' />
          <g clipPath='url(#clip2_3483_20972)'>
            <path
              d='M43 21.5L41.0667 23.25L36.3333 18.8125L36.3333 34L33.6667 34L33.6667 18.8125L28.9333 23.25L27 21.5L35 14L43 21.5Z'
              fill='#53B374'
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id='clip0_3483_20972'>
          <rect width='48' height='48' fill='white' />
        </clipPath>
        <clipPath id='clip1_3483_20972'>
          <rect width='48' height='48' fill='white' />
        </clipPath>
        <clipPath id='clip2_3483_20972'>
          <rect width='20' height='16' fill='white' transform='translate(27 34) rotate(-90)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IcHomeUp;

import { FC } from 'react';

import { TIcon } from './TIcon/TIcon';


const IcHouses: FC<TIcon> = ({ className, onClick }) => {
  return (
    <svg className={className} onClick={onClick} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 39V22.3333L18 14L29 22.3333V39H19.5714V30.6667H16.4286V39H7ZM10.1429 35.6667H13.2857V27.3333H22.7143V35.6667H25.8571V24L18 18.0833L10.1429 24V35.6667Z" fill="#53B374"/>
<g opacity="0.5">
<path d="M35.5385 19H32.3077V15.6667H35.5385V19Z" fill="#53B374"/>
<path d="M35.5385 25.6667H32.3077V22.3333H35.5385V25.6667Z" fill="#53B374"/>
<path d="M35.5385 32.3333H32.3077V29H35.5385V32.3333Z" fill="#53B374"/>
<path d="M32.3077 35.6667V39H42V9H21V12.25L24.2308 14.6667V12.3333H38.7692V35.6667H32.3077Z" fill="#53B374"/>
</g>
</svg>




  );
};

export default IcHouses;
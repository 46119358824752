import Head from 'next/head';
import React, { FC } from 'react';

type TMeta = {
  title?: string;
  description?: string;
  keywords?: string;
};

interface MainProps {
  meta: TMeta;
}

const NextWrapper: FC<MainProps> = ({ meta }) => {
  return (
    <Head>
      <meta name='description' content={meta?.description} />
      <meta name='keywords' content={meta?.keywords} />
      <meta property='og:title' content={meta?.title} />
      <meta property='og:description' content={meta?.description} />
      <meta property='og:site_name' content='Сахалинское ипотечное агентство' />
      <meta property='og:locale' content='ru_RU' />
      <meta property='og:image' content='https://sakhipoteka.ru/favicon.ico' />
      <meta property='og:image:width' content='100' />
      <meta property='og:image:height' content='100' />
      <meta property='twitter:title' content={meta?.title} />
      <meta property='twitter:description' content={meta?.description} />
      <meta property='twitter:site' content='Сахалинское ипотечное агентство' />
      <meta property='twitter:image' content='https://sakhipoteka.ru/favicon.ico' />
      <title>{meta?.title}</title>
    </Head>
  );
};

export default NextWrapper;

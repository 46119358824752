import makeRequest from '@/api/makeRequest';

import {
  GetLastNewsResponse,
  GetNewsItemResponse,
  GetNewsListResponse,
} from '@/types/api/INewsResponse';

class NewsService {
  fetchNewsList(query: string) {
    return makeRequest<GetNewsListResponse>({
      url: `api/news${query}`,
    });
  }
  fetchNewsItem(id: string) {
    return makeRequest<GetNewsItemResponse>({
      url: `api/news/${id}`,
    });
  }
  fetchHomeNewsList() {
    return makeRequest<GetNewsListResponse>({
      url: 'api/home_news',
    });
  }
  fetchLastNewsList() {
    return makeRequest<GetLastNewsResponse>({
      url: 'api/last_news',
    });
  }
}

export default new NewsService();

import { FC, ReactNode } from 'react';

import clsx from 'clsx';

import styles from './SectionWrapper.module.scss';

// Есть пропсы paddingTop и paddingBottom, которые отвечают паддинги, если пропсы true, то паддинги есть и наоборот
interface SectionWrapperProps {
  children: ReactNode;
  className?: string;
  ptNone?: boolean;
  pbNone?: boolean;
}

const SectionWrapper: FC<SectionWrapperProps> = ({ children, className = '', ptNone, pbNone }) => {
  const wrapperStyles: string = clsx({
    [styles.section]: true,
    [styles.topNone]: ptNone,
    [styles.bottomNone]: pbNone,
    [className]: className,
  });

  return <section className={wrapperStyles}>{children}</section>;
};

export default SectionWrapper;

import { FC } from 'react';
import Select, { StylesConfig } from 'react-select';

import { observer } from 'mobx-react-lite';

import { SortProps } from './types';

const Sort: FC<SortProps> = observer(({ name, options, minWidth, isDesktop, value, ...props }) => {
  const customStyles: StylesConfig = {
    control: (provided) => ({
      ...provided,
      borderRadius: 8,
      border: 'none',
      cursor: 'pointer',
      boxShadow: 'none',
      backgroundColor: 'inherit',
      minWidth: minWidth ? minWidth : 'none',
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: isDesktop ? '2px 8px' : 0,
      marginRight: isDesktop ? 0 : 5,
      width: 'auto',
      borderRadius: 8,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      opacity: 0.5,
      padding: isDesktop ? '0px 2px' : 0,
    }),
    indicatorSeparator: () => ({
      isDisabled: true,
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: 8,
      position: 'absolute',
      zIndex: 9999,
      margin: 0,
      padding: 0,
      border: '1px solid #8692A6',
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999,
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      borderRadius: 5,
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: 16,
      color: '#5D6963',
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: 16,
    }),
    option: (provided, state) => ({
      ...provided,
      // height: 52,
      padding: '14px 15px',
      fontSize: 16,
      cursor: 'pointer',
      borderRadius: 8,
      backgroundColor: state.isSelected ? '#53B374' : state.isFocused ? '#D3DDE0' : '#F5F9FF',
      color: state.isSelected ? 'white' : '#2f2f2f',
      transition: 'all .15s ease-in-out',
    }),
  };

  return (
    <Select
      name={name}
      options={options}
      styles={customStyles}
      value={value}
      isSearchable={false}
      menuPortalTarget={document.body}
      {...props}
    />
  );
});

export default Sort;

import { FC } from 'react';

import { TIcon } from '../TIcon/TIcon';

const IcHomeTime: FC<TIcon> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_5128_21648)'>
        <path
          d='M12 38H18H30H36V20L24 11L12 20V38ZM8 42V18L24 6L40 18V42H26H22H8Z'
          fill='#53B374'
        />
        <path d='M25 14L44 -2V38H25V14Z' fill='white' />
        <path
          d='M39.316 25V24.85H39.166H35.9827V20.8333V20.6833H35.8327H34.166H34.016V20.8333V26.6666V26.8166H34.166H39.166H39.316V26.6666V25ZM26.516 25C26.516 29.6853 30.314 33.4833 34.9993 33.4833C39.6847 33.4833 43.4827 29.6853 43.4827 25C43.4827 20.3146 39.6847 16.5166 34.9993 16.5166C30.314 16.5166 26.516 20.3146 26.516 25ZM39.6073 29.6079C38.3852 30.8301 36.7277 31.5166 34.9993 31.5166C33.271 31.5166 31.6135 30.8301 30.3914 29.6079C29.1693 28.3858 28.4827 26.7283 28.4827 25C28.4827 23.2716 29.1693 21.6141 30.3914 20.392C31.6135 19.1699 33.271 18.4833 34.9993 18.4833C36.7277 18.4833 38.3852 19.1699 39.6073 20.392C40.8294 21.6141 41.516 23.2716 41.516 25C41.516 26.7283 40.8294 28.3858 39.6073 29.6079Z'
          fill='#53B374'
          stroke='#53B374'
          strokeWidth='0.3'
        />
      </g>
      <defs>
        <clipPath id='clip0_5128_21648'>
          <rect width='48' height='48' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IcHomeTime;

import { FC } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';

import clsx from 'clsx';

import { DownloadBtn } from '@/components/UI';

import { TAccordionType } from '@/types/general/unions';

import styles from './MainAccordion.module.scss';
import Image from 'next/image';

interface MainAccordionProps {
  data: { title: string; content: string | JSX.Element; type: TAccordionType }[];
  isQuestionsPage?: boolean;
  isHousesListPage?: boolean;
}

const MainAccordion: FC<MainAccordionProps> = ({ data, isQuestionsPage, isHousesListPage }) => {
  const buttonStyles = clsx(styles.button, {
    [styles.questionPage]: isQuestionsPage,
    [styles.housesListPage]: isHousesListPage,
  });
  const contentStyles = clsx(styles.content, {
    [styles.questionPage]: isQuestionsPage,
  });
  return (
    <Accordion allowZeroExpanded={true} allowMultipleExpanded={true}>
      {data.map((item, index) => {
        return (
          <AccordionItem className={styles.item} key={index}>
            <AccordionItemHeading>
              <AccordionItemButton className={buttonStyles}>{item.title}</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className={contentStyles}>
              {item.type === 'image' ? (
                <img src={`${item.content}`} alt='' />
              ) : item.type === 'file' ? (
                typeof item.content === 'string' && <DownloadBtn href={item.content} />
              ) : (
                <p className={'color-dark'}>{item.content}</p>
              )}
            </AccordionItemPanel>
          </AccordionItem>
        );
      })}
    </Accordion>
  );
};

export default MainAccordion;

import makeRequest from '@/api/makeRequest';

import { CheckAuthResponse, LoginResponse, RegistrationResponse } from '@/types/api/IAuthResponse';
import { TRegistrationAccount } from '@/types/entities/IRegistration';
import { TUserLogin } from '@/types/entities/IUser';

class AuthService {
  registration(account: TRegistrationAccount) {
    return makeRequest<RegistrationResponse>({
      url: 'api/auth/register',
      method: 'post',
      data: account,
    });
  }

  authorization(account: TUserLogin) {
    return makeRequest<LoginResponse>({
      url: 'api/auth/login',
      method: 'post',
      data: account,
    });
  }

  logout() {
    return makeRequest<void>({
      url: 'api/auth/logout',
      method: 'delete',
      authToken: true,
    });
  }

  checkAuth() {
    if (typeof window !== 'undefined'){
      const token = localStorage.getItem('auth_token');

      if (token === null) {
        return false;
      }

      return makeRequest<CheckAuthResponse>({
        url: 'api/profile',
        authToken: true,
      });
    }
  }
}

export default new AuthService();

import { FC } from 'react';

import { TIcon } from '../TIcon/TIcon';

const IcHomeFile: FC<TIcon> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_6354_80615)'>
        <path
          d='M10 38H16H28H34V20L22 11L10 20V38ZM6 42V18L22 6L38 18V42H24H20H6Z'
          fill='#53B374'
        />
        <g clipPath='url(#clip1_6354_80615)'>
          <path d='M25 14L44 -2V38H25V14Z' fill='white' />
          <g clipPath='url(#clip2_6354_80615)'>
            <path
              d='M28 31.9531H38.418V21.4H35.1006V18H28V31.9531ZM26.1006 33.1V16H36.9006L40.5006 19.6V34H26.1006V33.1Z'
              fill='#53B374'
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id='clip0_6354_80615'>
          <rect width='48' height='48' fill='white' />
        </clipPath>
        <clipPath id='clip1_6354_80615'>
          <rect width='48' height='48' fill='white' />
        </clipPath>
        <clipPath id='clip2_6354_80615'>
          <rect width='18' height='18' fill='white' transform='translate(24 16)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IcHomeFile;

// @ts-nocheck
import { NewsService } from '@/api';
import { makeAutoObservable, toJS } from 'mobx';

import { TNewsCamelize, TNewsView } from '@/types/entities/INews';
import { TNewsPhotoCamelize, TNewsPhotoResponse } from '@/types/general/unions';
import { INewsItemStore } from '@/types/stores/INewsItemStore';

export class NewsItem implements INewsItemStore {
  news: TNewsCamelize;
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  get profile() {
    return toJS(this.news);
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setNewsItem(news: TNewsCamelize) {
    this.news = news;
  }

  serializeNewsItem(item: TNewsView) {
    return {
      id: item.id,
      name: item.name,
      text: item.text,
      type: item.type,
      images: this.serializePhotos(item.images),
      updatedAt: item.updated_at,
      createdAt: item.created_at,
    };
  }

  serializePhotos(photos?: TNewsPhotoResponse[]): TNewsPhotoCamelize[] {
    return (
      photos?.map((photo) => {
        return {
          id: photo.id,
          url: photo.url,
          thumbUrl: photo.thumb_url,
        };
      }) || ([] as TNewsPhotoCamelize[])
    );
  }

  async fetchNewsItem(id: string) {
    this.setLoading(true);

    const response = await NewsService.fetchNewsItem(id);

    if ('data' in response) {
      this.setNewsItem(this.serializeNewsItem(response.data.news));
    }

    this.setLoading(false);
  }
}

import { FC } from 'react';

import styles from './MapPlug.module.scss';

import IcInWork from '@/components/Icons/IcInWork';

const MapPlug: FC<{ height: number }> = ({ height }) => {
  const mapPlugStyles = {
    height,
  };

  return (
    <div className={styles.plug} style={mapPlugStyles}>
      <div className={styles.wrapper}>
        <IcInWork />
        <span>Карты недоступны</span>
        <p>В скором времени вернем их в рабочее состояние</p>
      </div>
    </div>
  );
};

export default MapPlug;

import makeRequest from '@/api/makeRequest';

import {
  AgencyApartmentBooking,
  GetAgencyApartmentResponse,
  GetAgencyApartmentsResponse,
  GetAgencyHousesResponse,
} from '@/types/api/IAgencyHousesResponse';
import { IBooking } from '@/types/entities/IAgencyHouse';

class AgencyHousesService {
  fetchAgencyHouses() {
    return makeRequest<GetAgencyHousesResponse>({
      url: 'api/agency_houses',
    });
  }

  fetchAgencyApartments(id: string, query: string) {
    return makeRequest<GetAgencyApartmentsResponse>({
      url: `api/agency_houses/${id}/agency_apartments${query}`,
    });
  }

  fetchAgencyApartment(parentId: string, id: string) {
    return makeRequest<GetAgencyApartmentResponse>({
      url: `api/agency_houses/${parentId}/agency_apartments/${id}`,
    });
  }

  bookApartment(info: IBooking, needAuth: boolean) {
    return makeRequest<AgencyApartmentBooking>({
      url: 'api/booking',
      authToken: needAuth,
      method: 'POST',
      data: info,
    });
  }
}

export default new AgencyHousesService();

import { FC } from 'react';
import Link from 'next/link';
import parse from 'html-react-parser';

import { ImageWithBlur } from '@/components/UI';

import { formatToSimpleView } from '@/utils/dateFunctions';

import styles from './NewsCard.module.scss';

import IcPlug from '@/components/Icons/IcPlug';

interface NewsCardProps {
  img: string;
  title: string;
  newsContent: string;
  date: string;
  path: string;
}

const NewsCard: FC<NewsCardProps> = ({ img, title, newsContent, date, path }) => {
  return (
    <article className={styles.newsCard}>
      <div className={styles.body}>
        <Link className={styles.link} href={path}></Link>
        <div className={styles.imgWrapper}>{img ? <ImageWithBlur url={img} /> : <IcPlug />}</div>

        <div className={styles.content}>
          <p className={styles.date}>{formatToSimpleView(date)}</p>
          <h2 className={styles.title}>{title}</h2>
          <div className={styles.newsContent}>{parse(newsContent)}</div>
        </div>
      </div>
    </article>
  );
};

export default NewsCard;

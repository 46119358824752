import { TNewsPhotoCamelize, TNewsPhotoResponse } from '../types/general/unions';
import { PaginationStore } from './pagination';
import { PhotoReportService } from '@/api';
import { action, computed, makeObservable, observable, values } from 'mobx';

import {
  TLastPhotoReportCamelize,
  TLastPhotoReportView,
  TPhotoReportCamelize,
  TPhotoReportView,
} from '@/types/entities/IPhotoReports';
import { IPhotoReportsStore } from '@/types/stores/IPhotoReportsStore';

export class PhotoReports extends PaginationStore implements IPhotoReportsStore {
  photoReports = observable.map<number, TPhotoReportCamelize>();
  homePhotoReports = observable.map<number, TPhotoReportCamelize>();
  lastPhotoReports = observable.map<number, TLastPhotoReportCamelize>();
  isLoading = false;

  constructor() {
    super();
    makeObservable(this, {
      photoReports: observable,
      homePhotoReports: observable,
      lastPhotoReports: observable,
      isLoading: observable,
      list: computed,
      homeList: computed,
      lastList: computed,
      setLoading: action,
      setPhotoReport: action,
      setHomePhotoReport: action,
    });
  }

  get list() {
    return values(this.photoReports);
  }

  get homeList() {
    return values(this.homePhotoReports);
  }

  get lastList() {
    return values(this.lastPhotoReports);
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setPhotoReport(item: TPhotoReportCamelize) {
    this.photoReports.set(item.id, item);
  }

  setHomePhotoReport(item: TPhotoReportCamelize) {
    this.homePhotoReports.set(item.id, item);
  }

  setLastPhotoReport(item: TLastPhotoReportCamelize) {
    this.lastPhotoReports.set(item.id, item);
  }

  serializePhotoReport(item: TPhotoReportView) {
    return {
      id: item.id,
      name: item.name,
      text: item.text,
      type: item.type,
      images: this.serializePhotos(item.images),
      updatedAt: item.updated_at,
      createdAt: item.created_at,
    };
  }

  serializeLastPhotoReport(item: TLastPhotoReportView) {
    return {
      id: item.id,
      name: item.name,
      images: this.serializePhotos(item.images),
      updatedAt: item.updated_at,
      createdAt: item.created_at,
    };
  }

  serializePhotos(photos?: TNewsPhotoResponse[]): TNewsPhotoCamelize[] {
    return (
      photos?.map((photo) => {
        return {
          id: photo.id,
          url: photo.url,
          thumbUrl: photo.thumb_url,
        };
      }) || ([] as TNewsPhotoCamelize[])
    );
  }

  async fetchPhotoReports(query: string) {
    this.photoReports.clear();
    this.setLoading(true);

    const response = await PhotoReportService.fetchPhotoReports(query);

    if ('data' in response) {
      response.data.news?.forEach((item) => {
        this.setPhotoReport(this.serializePhotoReport(item));
      });

      const { meta } = response.data;

      this.setLastPage(meta?.last_page ?? 1);
      this.setCurrentPage(meta?.current_page ?? 1);
      this.setAmount(meta?.total);
    } else {
      this.setLastPage(1);
      this.setCurrentPage(1);
      this.setAmount(0);
    }

    this.setLoading(false);
  }

  async fetchHomePhotoReports() {
    this.homePhotoReports.clear();
    this.setLoading(true);

    const response = await PhotoReportService.fetchHomePhotoReports();

    if ('data' in response) {
      response.data.news?.forEach((item) => {
        this.setHomePhotoReport(this.serializePhotoReport(item));
      });
    }

    this.setLoading(false);
  }

  async fetchLastPhotoReports() {
    this.lastPhotoReports.clear();
    this.setLoading(true);

    const response = await PhotoReportService.fetchLastPhotoReports();

    if ('data' in response) {
      response.data.news?.forEach((item) => {
        this.setLastPhotoReport(this.serializeLastPhotoReport(item));
      });
    }

    this.setLoading(false);
  }
}

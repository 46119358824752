import { FC } from 'react';

import { TIcon } from '../TIcon/TIcon';

const IcHomeHelp: FC<TIcon> = ({ className, onClick }) => {
  return (
    <svg
      onClick={onClick}
      className={className}
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_7414_22175)'>
        <path
          d='M12 38H18H30H36V20L24 11L12 20V38ZM8 42V18L24 6L40 18V42H26H22H8Z'
          fill='#53B374'
        />
        <g clipPath='url(#clip1_7414_22175)'>
          <path d='M25 14L44 -2V38H25V14Z' fill='white' />
          <path
            d='M46.7821 18.5637C48.2774 17.0684 47.5956 15.3219 46.7821 14.4726L43.1932 10.8839C41.6858 9.38861 39.9512 10.0705 39.1018 10.8839L37.0681 12.9295H33.9697C31.6968 12.9295 30.3808 14.1257 29.7109 15.5014L24.3993 20.8127V25.5976L23.55 26.435C22.0546 27.9422 22.7365 29.6768 23.55 30.5261L27.1389 34.1148C27.7849 34.7607 28.4787 35 29.1367 35C29.986 35 30.7636 34.5813 31.2302 34.1148L34.4602 30.873H38.7549C40.7886 30.873 41.8174 29.605 42.1883 28.3609C43.5401 28.002 44.2818 26.9733 44.5809 25.9684C46.4351 25.4899 47.129 23.7315 47.129 22.4993V18.9106H46.4232L46.7821 18.5637ZM44.7364 22.4993C44.7364 23.0376 44.5091 23.6956 43.5401 23.6956H42.3438V24.8918C42.3438 25.4301 42.1165 26.088 41.1475 26.088H39.9512V27.2843C39.9512 27.8226 39.7239 28.4805 38.7549 28.4805H33.4792L29.5554 32.4042C29.1845 32.7511 28.9692 32.5477 28.8376 32.4161L25.2607 28.8514C24.9137 28.4805 25.1171 28.2652 25.2487 28.1336L26.7919 26.5785V21.7936L29.1845 19.4011V21.3031C29.1845 22.7506 30.1416 24.8918 32.7734 24.8918C35.4053 24.8918 36.3623 22.7506 36.3623 21.3031H44.7364V22.4993ZM45.0833 16.8651L43.0496 18.9106H33.9697V21.3031C33.9697 21.8414 33.7424 22.4993 32.7734 22.4993C31.8044 22.4993 31.5771 21.8414 31.5771 21.3031V17.7144C31.5771 17.1641 31.7805 15.3219 33.9697 15.3219H38.0491L40.7767 12.5945C41.1475 12.2476 41.3628 12.451 41.4944 12.5826L45.0714 16.1473C45.4183 16.5182 45.2149 16.7335 45.0833 16.8651Z'
            fill='#53B374'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_7414_22175'>
          <rect width='48' height='48' fill='white' />
        </clipPath>
        <clipPath id='clip1_7414_22175'>
          <rect width='48' height='48' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IcHomeHelp;

import { ToastContentProps } from 'react-toastify';
import Link from 'next/link';

import { staticLinks } from '@/config/routingLinks';

import IcClose from '@/components/Icons/form/IcClose';
import IcHomeUp from '@/components/Icons/header/IcHomeUp';
import styles from './HomeApplication.module.scss';

const HomeApplicationMessage = ({ closeToast }: Partial<ToastContentProps>) => {
  const onMessageClose = () => {
    if (closeToast) closeToast();
  };

  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <IcHomeUp />
      </div>
      <div className={styles.info}>
        <h3>Улучшение жилищных условий</h3>
        <span>
          Узнайте более{' '}
          <Link href={staticLinks.homeQRs} onClick={onMessageClose}>
            подробную информацию
          </Link>{' '}
          и{' '}
          <Link href={staticLinks.homeApplication} onClick={onMessageClose}>
            как подать заявку
          </Link>
        </span>
      </div>
      <IcClose onClick={onMessageClose} className={styles.closeBtn} />
    </div>
  );
};

export default HomeApplicationMessage;

import { FC } from 'react';

import { TIcon } from './TIcon/TIcon';

const IcLogout: FC<TIcon> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width='21'
      height='21'
      viewBox='0 0 27 27'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M15.1875 7.875L16.7625 9.50625L13.8938 12.375L23.625 12.375L23.625 14.625L13.8938 14.625L16.7625 17.4937L15.1875 19.125L9.5625 13.5L15.1875 7.875ZM23.625 3.375L23.625 10.125L21.375 10.125L21.375 5.625L5.625 5.625L5.625 21.375L21.375 21.375L21.375 16.875L23.625 16.875L23.625 23.625L3.375 23.625L3.375 3.375L23.625 3.375Z'
        fill='#53B374'
      />
    </svg>
  );
};

export default IcLogout;

import { FC, useEffect } from 'react';

import clsx from 'clsx';

import { CategoryCard } from '@/components/UI';
import { useScrollbarWidth } from '@/hooks/useScrollbarWidth';

import { menuData } from './menu.data';

import { THeaderPopovers } from '@/types/general/unions';

import styles from './Menu.module.scss';
import IcClose from '@/components/Icons/form/IcClose';

interface MenuProps {
  isMenuActive: boolean;
  closeMenu: () => void;
  currentTab: THeaderPopovers;
}

const Menu: FC<MenuProps> = ({ isMenuActive, closeMenu, currentTab }) => {
  const scrollbarWidth = useScrollbarWidth();

  useEffect(() => {
    if (!isMenuActive) {
      document.body.style.overflow = 'initial';
      document.body.style.paddingRight = '0';
    } else {
      document.body.style.overflow = 'hidden';
      document.body.style.paddingRight = scrollbarWidth + 'px';
      document.documentElement.style.setProperty('--scroll-width', `${scrollbarWidth}px`);
    }
  }, [isMenuActive]);

  const menuWrapperStyles: string = clsx({
    [styles.menuWrapper]: true,
    [styles.visible]: isMenuActive,
  });
  const menuStyles: string = clsx({
    [styles.menu]: true,
    [styles.visible]: isMenuActive,
  });

  return (
    <div onClick={closeMenu} className={menuWrapperStyles}>
      <div onClick={(event) => event.stopPropagation()} className={menuStyles}>
        {currentTab && (
          <div className={styles.menuContent}>
            <h1>{menuData[currentTab].title}</h1>

            <div className={styles.rowCard}>
              {menuData[currentTab].categories.map((card) => {
                return (
                  <CategoryCard
                    key={card.title}
                    icon={card.icon}
                    title={card.title}
                    subtitle={card.subtitle}
                    path={card.path}
                    closePopover={closeMenu}
                  />
                );
              })}
            </div>
          </div>
        )}

        <button onClick={closeMenu} className={styles.closeBtn}>
          <i>
            <IcClose />
          </i>
        </button>
      </div>
    </div>
  );
};

export default Menu;

// @ts-nocheck
import { TAdvertUserCamelize, TAdvertUserResponse } from '../../types/entities/IUser';
import { makeAutoObservable } from 'mobx';

import {
  TAdvertItemCamelize,
  TAdvertItemResponse,
  TExternalAdvertItemCamelize,
  TExternalAdvertItemResponse,
} from '@/types/entities/IAdvert';
import {
  TAdvertPhotoCamelize,
  TAdvertPhotoResponse,
  TAppointments,
  TDeals,
  TFile,
  TMeasuringArea,
  TProperties,
  TRents,
} from '@/types/general/unions';

export class AdvertItem implements TAdvertItemCamelize {
  id: number;
  propertyType: TProperties;
  dealType: TDeals;
  rentType?: TRents;
  address: string;
  latitude: number;
  longitude: number;
  isOwner: boolean;
  totalArea: string;
  roomsCount: number;
  measuringPlotArea?: TMeasuringArea;
  appointment?: TAppointments;
  plotArea?: number;
  floor: number;
  totalFloors: number;
  price: number;
  isPossibleMortgage?: boolean;
  commission?: number;
  deposit?: number;
  photo: TAdvertPhotoCamelize | null;
  createdAt: string;
  updatedAt: string;
  isApproved: boolean;
  isAgencyAdvert: boolean;

  constructor(item?: TAdvertItemResponse) {
    if (item) {
      this.id = item.id;
      this.propertyType = item.property_type;
      this.dealType = item.deal_type;
      this.rentType = item.rent_type;
      this.address = item.address;
      this.latitude = item.latitude;
      this.longitude = item.longitude;
      this.isOwner = item.is_owner;
      this.totalArea = item.total_area;
      this.isPossibleMortgage = item.is_possible_mortgage;
      this.photo = item.photo ? this.serializePhoto(item.photo) : null;
      this.price = item.price;
      this.deposit = item.deposit;
      this.commission = item.commission;
      this.roomsCount = item.rooms_count;
      this.measuringPlotArea = item.measuring_plot_area;
      this.appointment = item.appointment;
      this.plotArea = item.plot_area;
      this.totalFloors = item.total_floors;
      this.floor = item.floor;
      this.createdAt = item.created_at;
      this.updatedAt = item.updated_at;
      this.isApproved = item.is_approved;
      this.isAgencyAdvert = item.is_agency_advert;
    }
    makeAutoObservable(this);
  }

  serializePhoto(photo: TAdvertPhotoResponse): TAdvertPhotoCamelize {
    return {
      id: photo.id,
      description: photo.description,
      url: photo.url,
      isMain: photo.is_main,
      thumbUrl: photo.thumb_url,
    };
  }
  serializeUser(user: TAdvertUserResponse): TAdvertUserCamelize {
    return {
      id: user.id,
      firstName: user.first_name,
      lastName: user.last_name,
      patronymicName: user.patronymic_name,
      email: user.email,
      phone: user.phone,
    };
  }
}

export class ExternalAdvertItem implements TExternalAdvertItemCamelize {
  id: number;
  dealType: TDeals;
  rentType?: TRents;
  name: string;
  link: string;
  platform: string;
  address: string;
  latitude: number;
  longitude: number;
  price: number;
  deposit: number;
  commission: string | null;
  roomsCount: number;
  totalFloors: number;
  floor: number;
  publishedAt: string;
  seller: string;
  photos: TFile[];

  constructor(item?: TExternalAdvertItemResponse) {
    if (item) {
      this.id = item.id;
      this.dealType = item.deal_type;
      this.rentType = item.rent_type;
      this.name = item.name;
      this.link = item.link;
      this.platform = item.platform;
      this.address = item.address;
      this.latitude = item.latitude;
      this.longitude = item.longitude;
      this.price = item.price;
      this.deposit = item.deposit;
      this.commission = item.commission;
      this.roomsCount = item.rooms_count;
      this.totalFloors = item.total_floors;
      this.floor = item.floor;
      this.publishedAt = item.published_at;
      this.photos = item.photos;
      this.seller = item.seller;
    }
    makeAutoObservable(this);
  }
}

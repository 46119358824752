import { FC, useEffect } from 'react';
import { usePathname } from 'next/navigation';
import { useRouter } from 'next/router';

import { PaginationProps } from './types';

import IcArrowLeft from '@/components/Icons/IcArrowLeft';
import IcArrowRight from '@/components/Icons/IcArrowRight';
import styles from './Pagination.module.scss';

const Pagination: FC<PaginationProps> = ({ currentPage, lastPage }) => {
  const router = useRouter();
  const pathname = usePathname();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [router.query]);

  if (lastPage === 1 || lastPage === undefined) return null;
  const pages = [currentPage - 1, currentPage, currentPage + 1];
  const hasPreviousPage = currentPage - 1 > 0;
  const hasNextPage = currentPage + 1 <= lastPage;
  const isPrevPageNotFirst = currentPage - 1 > 1;
  const isShowPrevDots = isPrevPageNotFirst && currentPage > 3;
  const isNextPageNotLast = currentPage + 1 < lastPage;
  const isShowNextDots = isNextPageNotLast && currentPage < lastPage - 2;
  const onPrevPage = () => navigatePage(currentPage - 1);
  const onNextPage = () => navigatePage(currentPage + 1);

  const navigatePage = (page: number) => {
    router.query.page = page.toString();
    for (const [key, value] of Object.entries(router.query)) {
      if (Array.isArray(value)) {
        if (value.length) {
          router.query[key] = '';
          value.map((item) => {
            router.query[key] = router.query[key]
              ? router.query[key] + `&${key}=${item}`
              : router.query[key] + `${item}`;
          });
        }
      }
    }



    const queryNew =
      '?' +
      Object.entries(router.query)
        .map((item) => item.join('='))
        .join('&');
    router.push(`${pathname}${queryNew}`, undefined, { scroll: false });

  };

  return (
    <div className={styles.wrapper}>
      <button disabled={!hasPreviousPage} className={styles.buttonPrevious} onClick={onPrevPage}>
        <IcArrowLeft />
      </button>

      {isPrevPageNotFirst && (
        <button className={styles.button} onClick={() => navigatePage(1)}>
          1
        </button>
      )}

      {isShowPrevDots && <span className={styles.dots}>...</span>}

      {pages.map((page) => {
        if (page < 1) return null;
        if (page > lastPage) return null;

        return (
          <button
            key={page}
            className={`${styles.button} ${currentPage === page ? styles.active : ''}`}
            onClick={() => navigatePage(page)}
          >
            {page}
          </button>
        );
      })}

      {isShowNextDots && <span className={styles.dots}>...</span>}

      {isNextPageNotLast && (
        <button className={styles.button} onClick={() => navigatePage(lastPage)}>
          {lastPage}
        </button>
      )}

      <button disabled={!hasNextPage} className={styles.buttonNext} onClick={onNextPage}>
        <IcArrowRight />
      </button>
    </div>
  );
};

export default Pagination;

import { FC } from 'react';

import clsx from 'clsx';

import styles from './DownloadBtn.module.scss';

interface DownloadBtnProps {
  className?: string;
  href?: string;
}

const DownloadBtn: FC<DownloadBtnProps> = ({ className = '', href }) => {
  const btnStyles: string = clsx({
    [styles.downloadBtn]: true,
    [className]: className,
  });

  return (
    <a className={btnStyles} href={`${process.env.NEXT_PUBLIC_FRONT_URL}${href}`} download>
      Скачать документ
    </a>
  );
};

export default DownloadBtn;

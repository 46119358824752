import { TradeInAdvertItem } from './list/tradeInAdvertItem';
import { PaginationStore } from './pagination';
import { action, computed, makeObservable, observable, values } from 'mobx';

import TradeInAdvertService from '@/api/rest/TradeInAdvertService';

import {
  TTradeInAdvertItemCamelize,
  TTradeInAdvertItemResponse,
} from '@/types/entities/ITradeInAdvert';
import { ITradeInAdvertsStore } from '@/types/stores/ITradeInAdvertsStore';

export class TradeInAdverts extends PaginationStore implements ITradeInAdvertsStore {
  tradeInAdvertItems = observable.map<number, TTradeInAdvertItemCamelize>();
  isLoading = false;

  constructor() {
    super();
    makeObservable(this, {
      tradeInAdvertItems: observable,
      isLoading: observable,
      tradeInAdvertsList: computed,
      setLoading: action,
      setTradeInAdvert: action,
    });
  }

  get tradeInAdvertsList() {
    return values(this.tradeInAdvertItems);
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setTradeInAdvert(item: TTradeInAdvertItemResponse) {
    const newTradeInAdvert = new TradeInAdvertItem(item);
    this.tradeInAdvertItems.set(item.id, newTradeInAdvert);
  }

  async fetchTradeInAdverts(query: string) {
    this.tradeInAdvertItems.clear();
    this.setLoading(true);

    const response = await TradeInAdvertService.fetchTradeInAdverts(query);

    if ('data' in response) {
      response.data.trade_in_adverts.forEach((item) => {
        this.setTradeInAdvert(item);
      });

      const { meta } = response.data;

      this.setLastPage(meta?.last_page ?? 1);
      this.setCurrentPage(meta?.current_page ?? 1);
      this.setAmount(meta.total);
    } else {
      this.setLastPage(1);
      this.setCurrentPage(1);
      this.setAmount(0);
    }

    this.setLoading(false);
  }
}

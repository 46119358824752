import { FC } from 'react';

import { THeaderPopovers } from '../../../../types/general/unions';
import MobileMenu from '../MobileMenu/MobileMenu';
import clsx from 'clsx';

import styles from './Burger.module.scss';

interface BurgerProps {
  isActive: boolean;
  setIsBurgerActive: (isBurgerActive: boolean) => void;
  currentTab: THeaderPopovers;
}

const Burger: FC<BurgerProps> = ({ isActive, setIsBurgerActive, currentTab }) => {
  const rentStyles: string = clsx({
    [styles.burger]: true,
    [styles.active]: isActive,
  });

  return (
    <>
      <div className={rentStyles} role={'button'} onClick={() => setIsBurgerActive(!isActive)}>
        <span></span>
        <span></span>
        <span></span>
      </div>

      <MobileMenu
        isMobileMenuActive={isActive}
        setIsBurgerActive={setIsBurgerActive}
        currentTab={currentTab}
      />
    </>
  );
};

export default Burger;

import type { AppProps } from 'next/app';

import Layout from '@/components/layouts/Layout/layout';
import '/public/globals.scss';
import 'swiper/swiper.min.css';
import 'swiper/css';
import '@/assets/styles/swiper.scss';
import 'swiper/css/navigation';
import './styles/MapComponent.scss';
// import './HomeBuyout/SwiperStyles.scss';
import Head from 'next/head';
import * as Sentry from '@sentry/react';
import StoreProvider from '../components/Logic/StoreProvider/StoreProvider';

export default Sentry.withProfiler(function App({ Component, pageProps }: AppProps) {
  return (
    <>
      <Head>
        <meta name='viewport' content='width=device-width, initial-scale=1' />
      </Head>
      <Layout>
        <StoreProvider>
          <Component {...pageProps} />
        </StoreProvider>
      </Layout>
    </>
  );
});

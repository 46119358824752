import { FC } from 'react';

const IcArrowLeft: FC = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M13 16L7 9.99999L13 4'
        stroke='#5D6963'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IcArrowLeft;

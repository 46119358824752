import makeRequest from '@/api/makeRequest';

import { GetPersonalAdvertsResponse } from '@/types/api/IPersonalAdvertsResponse';

class PersonalAdvertsService {
  fetchPersonalAdverts(query: string) {
    return makeRequest<GetPersonalAdvertsResponse>({
      url: `api/personal_adverts${query}`,
      authToken: true,
    });
  }
}

export default new PersonalAdvertsService();

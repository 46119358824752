import { FC } from 'react';
import { TIcon } from '../TIcon/TIcon';

const IcPaper: FC<TIcon> = ({ className, onClick }) => {
  return (
    <svg className={className} onClick={onClick} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 41V6H24.3469L34.7551 16.5V23.5H31.2857V18.25H22.6122V9.5H10.4694V37.5H20.8776V41H7ZM31.7628 27.9625L33.6276 29.8L26.949 36.5375V38.375H28.7704L35.449 31.6375L37.3138 33.475L29.8546 41H24.3469V35.4438L31.7628 27.9625ZM37.3138 33.475L31.7628 27.9625L35.4923 24.2L41 29.7563L37.3138 33.475Z" fill="#53B374"/>
</svg>

  );
};

export default IcPaper;
import { FC } from 'react';

import { ImageWithBlur } from '@/components/UI';

import { TExternalAdvertItemCamelize } from '@/types/entities/IAdvert';

import styles from './FlatCard.module.scss';
import IcPlug from '@/components/Icons/IcPlug';

interface FlatCardProps {
  item: TExternalAdvertItemCamelize;
}

const FlatCard: FC<FlatCardProps> = ({ item }) => {
  const formattedPrice = Intl.NumberFormat('ru-RU').format(item?.price);

  const price =
    item.dealType === 'Продажа'
      ? formattedPrice + ' ₽'
      : item.rentType === 'Длительно'
      ? formattedPrice + ' ₽ / мес.'
      : formattedPrice + ' ₽ / сут.';

  return (
    <div className={styles.flatCard}>
      <a className={styles.flatLink} href={item.link} target='_blank' rel='noreferrer'></a>
      <div className={styles.imgWrapper}>
        {item?.photos.length !== 0 ? (
          <>
            <ImageWithBlur url={`${item.photos[0]?.url}`} />
          </>
        ) : (
          <IcPlug />
        )}
      </div>

      <div className={styles.body}>
        <header className={styles.header}>
          <span>{item?.publishedAt ?? 'Дата не указана'}</span>
          <span>{item?.platform}</span>
        </header>

        <div className={styles.about}>
          <span className={styles.seller}>{item.seller}</span>

          <div className={styles.info}>{item.name}</div>

          <div className={styles.options}>
            <span className={styles.price}>{price}</span>
          </div>

          <p className={styles.address}>{item.address}</p>
        </div>
      </div>
    </div>
  );
};

export default FlatCard;

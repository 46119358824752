import { FC, createContext } from 'react';

import store from '@/store';

import { AppContextInterface, StoreProviderProps } from './types';

export const Context = createContext<AppContextInterface>({
  store: store,
});

const StoreProvider: FC<StoreProviderProps> = ({ children }) => {
  return (
    <Context.Provider
      value={{
        store: store,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default StoreProvider;

import  Link  from 'next/link';

import { CheckElementAccess } from '@/components/Logic';
import { Row, SectionWrapper } from '@/components/layouts/Containers';

import { staticLinks } from '@/config/routingLinks';

import styles from './Disclaimer.module.scss';

const Disclaimer = () => {
  return (
    <SectionWrapper pbNone>
      <h2 className={'mb-6'}>Разместите свою квартиру у нас</h2>

      <Row>
        <Row.Header>
          <h2>Примечание</h2>
        </Row.Header>
        <Row.Content>
          <div>
            <p>
              Вы также можете разместить свою квартиру на продажу и аренду. Независимо от того,
              хотите ли вы продать свою собственность, сдать ее в аренду или найти покупателя, у нас
              есть все необходимое для вас. Мы предлагаем удобную платформу, где вы можете
              разместить объявление о своей квартире и привлечь заинтересованных лиц. Наша база
              данных охватывает широкий круг потенциальных клиентов, включая покупателей и
              арендаторов, которые активно ищут жилье.
            </p>
          </div>

          <div className={'mt-6'}>
            <CheckElementAccess checkAuth={true}>
              <Link href={staticLinks.createAdvert} className={styles.advtBtn}>
                Разместить объявление
              </Link>
            </CheckElementAccess>
            <CheckElementAccess checkAuth={false}>
              <Link href={staticLinks.authorization} className={styles.advtBtn}>
                Разместить объявление
              </Link>
            </CheckElementAccess>
          </div>
        </Row.Content>
      </Row>
    </SectionWrapper>
  );
};

export default Disclaimer;


import { FC } from 'react';

import { TIcon } from '../TIcon/TIcon';


const IcHelpOutside: FC<TIcon> = ({ className, onClick }) => {
  return (
    <svg className={className} onClick={onClick} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M40.7271 18.3318C42.8805 16.1785 41.8985 13.6636 40.7271 12.4406L35.5591 7.27282C33.3885 5.1196 30.8907 6.10147 29.6676 7.27282L26.739 10.2184H22.2773C19.0043 10.2184 17.1093 11.941 16.1446 13.922L8.49598 21.5702V28.4605L7.27289 29.6663C5.11955 31.8368 6.10147 34.3345 7.27289 35.5576L12.4409 40.7253C13.3711 41.6555 14.3703 42 15.3177 42C16.5408 42 17.6606 41.3971 18.3324 40.7253L22.9836 36.0571H29.168C32.0965 36.0571 33.578 34.2312 34.1121 32.4397C36.0587 31.9229 37.1267 30.4415 37.5574 28.9945C40.2275 28.3055 41.2267 25.7733 41.2267 23.9991V18.8313H40.2103L40.7271 18.3318ZM37.7814 23.9991C37.7814 24.7742 37.454 25.7216 36.0587 25.7216H34.336V27.4442C34.336 28.2194 34.0087 29.1668 32.6133 29.1668H30.8907V30.8894C30.8907 31.6645 30.5634 32.6119 29.168 32.6119H21.571L15.9207 38.262C15.3867 38.7616 15.0766 38.4687 14.8871 38.2792L9.7363 33.1459C9.23673 32.6119 9.52958 32.3019 9.71908 32.1124L11.9413 29.873V22.9827L15.3867 19.5376V22.2765C15.3867 24.3608 16.7648 27.4442 20.5547 27.4442C24.3445 27.4442 25.7227 24.3608 25.7227 22.2765H37.7814V23.9991ZM38.2809 15.8857L35.3524 18.8313H22.2773V22.2765C22.2773 23.0516 21.95 23.9991 20.5547 23.9991C19.1593 23.9991 18.832 23.0516 18.832 22.2765V17.1087C18.832 16.3164 19.1248 13.6636 22.2773 13.6636H28.1516L32.0793 9.73611C32.6133 9.23656 32.9234 9.5294 33.1129 9.71888L38.2637 14.8522C38.7633 15.3862 38.4704 15.6962 38.2809 15.8857Z" fill="#53B374"/>
</svg>


  );
};

export default IcHelpOutside;
import { FC } from 'react';

import { TIcon } from './TIcon/TIcon';

const IcHomeStorage: FC<TIcon> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M10 42L6 18H42L38 42H10ZM13.35 38H34.65L37.2 22H10.8L13.35 38ZM18 30H30V26H18V30ZM10 16V12H38V16H10ZM14 10V6H34V10H14Z'
        fill='#53B374'
      />
    </svg>
  );
};

export default IcHomeStorage;

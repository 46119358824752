import makeRequest from '@/api/makeRequest';

import { GetAdvertsPoints, GetExtAdvertsPoints } from '@/types/api/IMapPointsResponse';

class MapPointsService {
  fetchAdvertsPoints(query: string) {
    return makeRequest<GetAdvertsPoints>({
      url: `api/map/adverts${query}`,
    });
  }

  fetchExtAdvertsPoints(query: string) {
    return makeRequest<GetExtAdvertsPoints>({
      url: `api/map/external_adverts${query}`,
    });
  }
}

export default new MapPointsService();

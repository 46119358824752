
import { FC } from 'react';
import { TIcon } from '../TIcon/TIcon';


const IcSuitcase: FC<TIcon> = ({ className, onClick }) => {
  return (
    <svg className={className} onClick={onClick} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 42V12H16V4H32V12H44V42H4ZM20 12H28V8H20V12ZM40 30H30V34H18V30H8V38H40V30ZM22 30H26V26H22V30ZM8 26H18V22H30V26H40V16H8V26Z" fill="#53B374"/>
</svg>


  );
};

export default IcSuitcase;
import { FC } from 'react';

import { ImageWithBlurProps } from './types';

import styles from './ImageWithBlur.module.scss';

const ImageWithBlur: FC<ImageWithBlurProps> = ({ url, ...props }) => {
  return (
    <>
      <div
        className={styles.blur}
        style={{
          background: `center/100% 100% no-repeat url(${url})`,
        }}
      />
      <img src={url} alt='image' className={styles.image} {...props} />
    </>
  );
};

export default ImageWithBlur;

import makeRequest from '@/api/makeRequest';

import { GetUserResponse } from '@/types/api/IUserResponse';
import { TUserUpdate } from '@/types/entities/IUser';

class AccountService {
  fetchProfile() {
    return makeRequest<GetUserResponse>({
      url: 'api/profile',
      authToken: true,
    });
  }

  updateProfile(account: TUserUpdate) {
    return makeRequest<GetUserResponse>({
      url: 'api/profile',
      method: 'put',
      data: account,
      authToken: true,
    });
  }
}

export default new AccountService();
//

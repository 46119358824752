// @ts-nocheck
import { AdvertMapPointItem, ExtAdvertMapPointItem } from './list/mapPointItem';
import { PaginationStore } from './pagination';
import { action, computed, makeObservable, observable, values } from 'mobx';

import MapPointsService from '@/api/rest/MapPointsService';

import {
  TAdvertsPointsCamelize,
  TAdvertsPointsResponse,
  TExtAdvertsPointsCamelize,
  TExtAdvertsPointsResponse,
} from '@/types/entities/IMapPoints';
import { IMapPointsStore } from '@/types/stores/IMapPointsStore';

export class MapPoints extends PaginationStore implements IMapPointsStore {
  advertsPoints = observable.map<number, TAdvertsPointsCamelize>();
  externalAdvertsPoints = observable.map<number, TExtAdvertsPointsCamelize>();
  isLoading = false;

  constructor() {
    super();
    makeObservable(this, {
      advertsPoints: observable,
      externalAdvertsPoints: observable,
      isLoading: observable,
      advertsPointsList: computed,
      externalAdvertsPointsList: computed,
      setLoading: action,
      setAdvertsPoints: action,
      setExtAdvertsPoints: action,
    });
  }

  get advertsPointsList() {
    return values(this.advertsPoints);
  }

  get externalAdvertsPointsList() {
    return values(this.externalAdvertsPoints);
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setAdvertsPoints(item: TAdvertsPointsResponse) {
    const newPoints = new AdvertMapPointItem(item);
    this.advertsPoints.set(item.id, newPoints);
  }

  setExtAdvertsPoints(item: TExtAdvertsPointsResponse) {
    const newPoints = new ExtAdvertMapPointItem(item);
    this.externalAdvertsPoints.set(item.id, newPoints);
  }

  async fetchAdvertsPoints(query: string) {
    this.advertsPoints.clear();
    this.setLoading(true);

    const response = await MapPointsService.fetchAdvertsPoints(query);

    if ('data' in response) {
      response.data.combining_points?.forEach((item) => {
        this.setAdvertsPoints(item);
      });
      const { meta } = response.data;

      this.setAmount(meta?.total);
    } else {
      this.setAmount(0);
    }

    this.setLoading(false);
  }

  async fetchExtAdvertsPoints(query: string) {
    this.externalAdvertsPoints.clear();
    this.setLoading(true);

    const response = await MapPointsService.fetchExtAdvertsPoints(query);

    if ('data' in response) {
      response.data.combining_points?.forEach((item) => {
        this.setExtAdvertsPoints(item);
      });
      const { meta } = response.data;

      this.setAmount(meta?.total);
    } else {
      this.setAmount(0);
    }

    this.setLoading(false);
  }
}

import { TAdvertPhotoCamelize, TAdvertPhotoResponse } from '../types/general/unions';
import { AdvertService } from '@/api';
import { action, makeAutoObservable, runInAction, toJS } from 'mobx';

import { APIError } from '@/types/api/IAPI';
import { TAdvertCU, TAdvertViewCamelize, TAdvertViewResponse } from '@/types/entities/IAdvert';
import { TAdvertUserCamelize, TAdvertUserResponse } from '@/types/entities/IUser';
import { IAdvertStore } from '@/types/stores/IAdvertStore';
import advert from '@/reactPages/Advert/Get/Advert';

export class Advert implements IAdvertStore {
  advert= {} as TAdvertViewCamelize;
  isLoading = true;
  photo= {} as TAdvertPhotoCamelize;

  constructor() {
    makeAutoObservable(this);
  }

  get profile() {
    return toJS(this.advert);
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setAdvert(advert: TAdvertViewCamelize) {
    this.advert = advert;
  }

  setPhoto(photo: TAdvertPhotoCamelize) {
    const changedPhotoIndex = this.advert.photos.findIndex((item) => item.id === photo.id);
    this.advert.photos[changedPhotoIndex] = photo;
  }

  deleteAdvertPhoto(id: number) {
    this.advert.photos = this.advert.photos?.filter((photo) => photo.id === id);
  }

  deleteAdvertFile(id: number) {
    this.advert.documents = this.advert.documents?.filter((document) => document.id === id);
  }

  serialize(advert: TAdvertViewResponse) {
    return {
      id: advert.id,
      propertyType: advert.property_type,
      dealType: advert.deal_type,
      rentType: advert.rent_type,
      address: advert.address,
      latitude: advert.latitude,
      longitude: advert.longitude,
      isOwner: advert.is_owner,
      complex: advert.complex,
      cadastralNumber: advert.cadastral_number,
      totalArea: advert.total_area,
      livingArea: advert.living_area,
      kitchenArea: advert.kitchen_area,
      roomsCount: advert.rooms_count,
      layout: advert.layout,
      plotArea: advert.plot_area,
      measuringPlotArea: advert.measuring_plot_area,
      appointment: advert.appointment,
      floor: advert.floor,
      totalFloors: advert.total_floors,
      wallMaterial: advert.wall_material,
      ceilingHeight: advert.ceiling_height,
      builtYear: advert.built_year,
      parking: advert.parking,
      repair: advert.repair,
      sideOfTheWorld: advert.side_of_the_world,
      balcony: advert.balcony,
      bathroom: advert.bathroom,
      electricity: advert.electricity,
      heating: advert.heating,
      waterSupply: advert.water_supply,
      sewerage: advert.sewerage,
      isHavePhone: advert.is_have_phone,
      isHaveTv: advert.is_have_tv,
      isHaveInternet: advert.is_have_internet,
      passengerLift: advert.passenger_lift,
      serviceLift: advert.service_lift,
      isHaveGas: advert.is_have_gas,
      isHaveSecurity: advert.is_have_security,
      isPossibleMortgage: advert.is_possible_mortgage,
      price: advert.price,
      deposit: advert.deposit,
      commission: advert.commission,
      description: advert.description,
      periodOfPlacement: advert.period_of_placement,
      video: advert.video,
      photos: this.serializePhotos(advert.photos),
      documents: advert.documents,
      createdAt: advert.created_at,
      updatedAt: advert.updated_at,
      isApproved: advert.is_approved,
      isAgencyAdvert: advert.is_agency_advert,
      owner: this.serializeUser(advert.owner),
    };
  }

  serializeUser(user: TAdvertUserResponse): TAdvertUserCamelize {
    return {
      id: user.id,
      firstName: user.first_name,
      lastName: user.last_name,
      patronymicName: user.patronymic_name,
      email: user.email,
      phone: user.phone,
    };
  }

  serializePhotos(photos?: TAdvertPhotoResponse[]): TAdvertPhotoCamelize[] {
    return (
      photos?.map((photo) => {
        return {
          id: photo.id,
          description: photo.description,
          url: photo.url,
          isMain: photo.is_main,
          thumbUrl: photo.thumb_url,
        };
      }) || ([] as TAdvertPhotoCamelize[])
    );
  }
  serializePhoto(photo?: TAdvertPhotoResponse): TAdvertPhotoCamelize {
    if (photo) {
      return {
        id: photo.id,
        description: photo.description,
        url: photo.url,
        isMain: photo.is_main,
        thumbUrl: photo.thumb_url,
      };
    }
    return {} as TAdvertPhotoCamelize;
  }

  serializeErrors(errors: APIError['errors']) {
    const photosErrors = [];
    const documentsErrors: string[] = [];
    for (const [key, value] of Object.entries(errors)) {
      if (key.includes('photos') && Array.isArray(value) && typeof value[0] === 'string') {
        photosErrors.push(value[0].replace(key, `фото ${+key.replace('photos.','').replace('.photo','')+1}`));
        delete errors[key];
      } else if (key.includes('documents') && Array.isArray(value) && typeof value[0] === 'string') {
        errors.documents = value;
        documentsErrors.push(value[0].replace(key, `документ ${+key.replace('documents.','').replace('.document','')+1}`));
        delete errors[key];
      }
    }
    errors.documents = documentsErrors;
    errors.photos = photosErrors;
    return {
      propertyType: errors.property_type,
      dealType: errors.deal_type,
      rentType: errors.rent_type,
      address: errors.address,
      complex: errors.complex,
      cadastralNumber: errors.cadastral_number,
      totalArea: errors.total_area,
      livingArea: errors.living_area,
      kitchenArea: errors.kitchen_area,
      roomsCount: errors.rooms_count,
      layout: errors.layout,
      plotArea: errors.plot_area,
      measuringPlotArea: errors.measuring_plot_area,
      appointment: errors.appointment,
      floor: errors.floor,
      totalFloors: errors.total_floors,
      wallMaterial: errors.wall_material,
      ceilingHeight: errors.ceiling_height,
      builtYear: errors.built_year,
      parking: errors.parking,
      repair: errors.repair,
      sideOfTheWorld: errors.side_of_the_world,
      balcony: errors.balcony,
      bathroom: errors.bathroom,
      electricity: errors.electricity,
      heating: errors.heating,
      waterSupply: errors.water_supply,
      sewerage: errors.sewerage,
      isHavePhone: errors.is_have_phone,
      isHaveTv: errors.is_have_tv,
      isHaveInternet: errors.is_have_internet,
      passengerLift: errors.passenger_lift,
      serviceLift: errors.service_lift,
      isHaveGas: errors.is_have_gas,
      isHaveSecurity: errors.is_have_security,
      isPossibleMortgage: errors.is_possible_mortgage,
      price: errors.price,
      deposit: errors.deposit,
      commission: errors.commission,
      description: errors.description,
      periodOfPlacement: errors.period_of_placement,
      video: errors.video,
      photos: errors.photos,
      documents: errors.documents,
    };
  }

  async fetchAdvert(id: string) {
    this.setLoading(true);
    const response = await AdvertService.fetchAdvert(id);
    if ('data' in response) {
      this.setAdvert(this.serialize(response.data.advert));
    }

    this.setLoading(false);
  }

  async createAdvert(advert: FormData) {
    return await AdvertService.createAdvert(advert);
  }

  async updateAdvert(id: string, advert: TAdvertCU) {
    return await AdvertService.updateAdvert(id, advert);
  }

  async deleteAdvert(id: number) {
    return await AdvertService.deleteAdvert(id);
  }

  async updateFiles(advertId: string, files: FormData) {
    const response = await AdvertService.updateFiles(advertId, files);

    if ('data' in response) {
      this.setAdvert(this.serialize(response.data.advert));
    }
  }

  async updatePhotoDescription(advertId: string, photoId: number, description: string) {
    const response = await AdvertService.updatePhotoDescription(advertId, photoId, description);

    if ('data' in response) {
      const updatedPhoto = response.data.advert.photos?.find((photo) => photo.id === photoId);
      this.setPhoto(this.serializePhoto(updatedPhoto));
    }
  }

  async updateMainPhoto(advertId: string, photoId: number) {
    const response = await AdvertService.updateMainPhoto(advertId, photoId);

    if ('data' in response) {
      this.setAdvert(this.serialize(response.data.advert));
    }
  }

  async rotatePhoto(advertId: string, photoId: number, rotate: number) {
    const response = await AdvertService.rotatePhoto(advertId, photoId, rotate);

    if ('data' in response) {
      const updatedPhoto = response.data.advert.photos?.find((photo) => photo.id === photoId);
      this.setPhoto(this.serializePhoto(updatedPhoto));
    }
  }

  async deletePhoto(advertId: string, photoId: number) {
    const response = await AdvertService.deletePhoto(advertId, photoId);
    return response.status;
  }

  async deleteFile(advertId: string, fileId: number) {
    const response = await AdvertService.deleteFile(advertId, fileId);
    return response.status;
  }
}

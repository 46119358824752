import { FC } from 'react';

const IcArrowRight: FC = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M7 4L13 10L7.00001 16'
        stroke='#53B374'
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
};

export default IcArrowRight;

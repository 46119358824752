// @ts-nocheck
import { makeAutoObservable } from 'mobx';

import {
  TAdvertsPointsCamelize,
  TAdvertsPointsResponse,
  TExtAdvertsPointsCamelize,
  TExtAdvertsPointsResponse,
} from '@/types/entities/IMapPoints';

export class AdvertMapPointItem implements TAdvertsPointsCamelize {
  advertCount: number;
  advertsIds: number[];
  coords: { lng: number; lat: number };
  id: number;
  minPrice: number;

  constructor(item?: TAdvertsPointsResponse) {
    if (item) {
      this.advertCount = item.advertCount;
      this.advertsIds = item.adverts_ids;
      this.coords = item.coords;
      this.id = item.id;
      this.minPrice = item.minPrice;
    }
    makeAutoObservable(this);
  }
}

export class ExtAdvertMapPointItem implements TExtAdvertsPointsCamelize {
  advertCount: number;
  externalAdvertsIds: number[];
  coords: { lng: number; lat: number };
  id: number;
  minPrice: number;

  constructor(item?: TExtAdvertsPointsResponse) {
    if (item) {
      this.advertCount = item.advertCount;
      this.externalAdvertsIds = item.external_adverts_ids;
      this.coords = item.coords;
      this.id = item.id;
      this.minPrice = item.minPrice;
    }
    makeAutoObservable(this);
  }
}

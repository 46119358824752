import { FC, ReactNode } from 'react';
import Link from 'next/link';

import clsx from 'clsx';

import styles from './CategoryCardPost.module.scss';

interface CategoryCardPostProps {
  icon: ReactNode;
  title: string;
  subtitle: string;
  path: string;
  className?: string;
  iconMobileNone?: boolean;
}

const CategoryCardPost: FC<CategoryCardPostProps> = ({
  icon,
  subtitle,
  title,
  path,
  className = '',
  iconMobileNone,
}) => {
  const categoryCardStyles: string = clsx({
    [styles.categoryCard]: true,
    [className]: className,
  });

  return (
    <div className={categoryCardStyles}>
      <Link className={styles.link} href={path}></Link>
      {!iconMobileNone && <i className={styles.icon}>{icon}</i>}
      <h2>{title}</h2>
      <p>{subtitle}</p>
    </div>
  );
};

export default CategoryCardPost;

// @ts-nocheck
import { makeAutoObservable, toJS } from 'mobx';

import AccountService from '@/api/rest/AccountService';

import { TUserCamelize, TUserResponse, TUserUpdate } from '@/types/entities/IUser';
import { IAccountStore } from '@/types/stores/IAccountStore';
import { IRootStore } from '@/types/stores/IRootStore';

export class Account implements IAccountStore {
  rootStore: IRootStore;
  user: TUserCamelize;
  isLoading = true;

  constructor(rootStore: IRootStore, account: TUserResponse) {
    this.rootStore = rootStore;

    this.setUser(this.serialize(account));

    makeAutoObservable(this);
  }

  get profile() {
    return toJS(this.user);
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  serialize(account: TUserResponse) {
    return {
      id: account.id,
      email: account.email,
      phone: account.phone,
      firstName: account.first_name,
      lastName: account.last_name,
      patronymicName: account.patronymic_name,
      birthday: account.birthday,
      createdAt: account.created_at,
      updatedAt: account.updated_at,
    };
  }

  setUser(user: TUserCamelize) {
    this.user = user;
    this.setLoading(false);
  }

  async fetchUser() {
    this.setLoading(true);

    const response = await AccountService.fetchProfile();

    if ('data' in response) {
      this.setUser(this.serialize(response.data.user));
    }
    this.setLoading(false);
  }

  async updateUser(account: TUserUpdate) {
    const response = await AccountService.updateProfile(account);

    if ('data' in response) {
      this.setUser(this.serialize(response.data.user));
    }

    return response;
  }
}

import { FC, ReactNode } from 'react';

import clsx from 'clsx';

import styles from './Container.module.scss';

interface ContainerProps {
  children: ReactNode;
  className?: string;
}

const Container: FC<ContainerProps> = ({ children, className = '' }) => {
  const containerStyles: string = clsx({
    [styles.container]: true,
    [className]: className,
  });

  return <div className={containerStyles}>{children}</div>;
};

export default Container;

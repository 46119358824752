import { PaginationStore } from './pagination';
import { NewsService } from '@/api';
import { action, computed, makeObservable, observable, values } from 'mobx';

import { TLastNewsCamelize, TLastNewsView, TNewsCamelize, TNewsView } from '@/types/entities/INews';
import { TNewsPhotoCamelize, TNewsPhotoResponse } from '@/types/general/unions';
import { INewsListStore } from '@/types/stores/INewsListStore';

export class NewsList extends PaginationStore implements INewsListStore {
  news = observable.map<number, TNewsCamelize>();
  homeNews = observable.map<number, TNewsCamelize>();
  lastNews = observable.map<number, TLastNewsCamelize>();
  isLoading = false;

  constructor() {
    super();
    makeObservable(this, {
      news: observable,
      homeNews: observable,
      lastNews: observable,
      isLoading: observable,
      newsList: computed,
      homeNewsList: computed,
      lastNewsList: computed,
      setLoading: action,
      setNewsItem: action,
      setHomeNewsItem: action,
      setLastNewsItem: action,
    });
  }

  get newsList() {
    return values(this.news);
  }

  get homeNewsList() {
    return values(this.homeNews);
  }

  get lastNewsList() {
    return values(this.lastNews);
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setNewsItem(item: TNewsCamelize) {
    this.news.set(item.id, item);
  }

  setHomeNewsItem(item: TNewsCamelize) {
    this.homeNews.set(item.id, item);
  }

  setLastNewsItem(item: TLastNewsCamelize) {
    this.lastNews.set(item.id, item);
  }

  serializeNews(item: TNewsView) {
    return {
      id: item.id,
      name: item.name,
      text: item.text,
      type: item.type,
      images: this.serializePhotos(item.images),
      updatedAt: item.updated_at,
      createdAt: item.created_at,
    };
  }

  serializeLastNews(item: TLastNewsView) {
    return {
      id: item.id,
      name: item.name,
      images: this.serializePhotos(item.images),
      updatedAt: item.updated_at,
      createdAt: item.created_at,
    };
  }

  serializePhotos(photos?: TNewsPhotoResponse[]): TNewsPhotoCamelize[] {
    return (
      photos?.map((photo) => {
        return {
          id: photo.id,
          url: photo.url,
          thumbUrl: photo.thumb_url,
        };
      }) || ([] as TNewsPhotoCamelize[])
    );
  }

  async fetchNewsList(query: string) {
    this.news.clear();
    this.setLoading(true);

    const response = await NewsService.fetchNewsList(query);

    if ('data' in response) {
      response.data.news?.forEach((item) => {
        this.setNewsItem(this.serializeNews(item));
      });

      const { meta } = response.data;

      this.setLastPage(meta?.last_page ?? 1);
      this.setCurrentPage(meta?.current_page ?? 1);
      this.setAmount(meta?.total);
    } else {
      this.setLastPage(1);
      this.setCurrentPage(1);
      this.setAmount(0);
    }

    this.setLoading(false);
  }

  async fetchHomeNewsList() {
    this.homeNews.clear();
    this.setLoading(true);

    const response = await NewsService.fetchHomeNewsList();

    if ('data' in response) {
      response.data.news?.forEach((item) => {
        this.setHomeNewsItem(this.serializeNews(item));
      });
    }

    this.setLoading(false);
  }

  async fetchLastNewsList() {
    this.lastNews.clear();
    this.setLoading(true);

    const response = await NewsService.fetchLastNewsList();

    if ('data' in response) {
      response.data.news?.forEach((item) => {
        this.setLastNewsItem(this.serializeLastNews(item));
      });
    }

    this.setLoading(false);
  }
}

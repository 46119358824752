import { FC } from 'react';

import { ImageWrapperProps } from './types';

import styles from './ImageWrapper.module.scss';

const ImageWrapper: FC<ImageWrapperProps> = ({ image }) => {
  return (
    <div className={styles.wrapper}>
      <img src={image} alt='' />
    </div>
  );
};

export default ImageWrapper;

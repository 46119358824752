import { AdvertItem, ExternalAdvertItem } from './list/advertItem';
import { PaginationStore } from './pagination';
import { action, computed, makeObservable, observable, values } from 'mobx';

import AdvertService from '@/api/rest/AdvertService';

import {
  TAdvertItemCamelize,
  TAdvertItemResponse,
  TAdvertsIds,
  TExtAdvertsIds,
  TExternalAdvertItemCamelize,
  TExternalAdvertItemResponse,
} from '@/types/entities/IAdvert';
import { IAdvertsStore } from '@/types/stores/IAdvertsStore';

export class Adverts extends PaginationStore implements IAdvertsStore {
  advertItems = observable.map<number, TAdvertItemCamelize>();
  externalAdvertItems = observable.map<number, TExternalAdvertItemCamelize>();
  focusCoords = [46.9641, 142.7285] as [number, number];
  isLoading = false;

  constructor() {
    super();
    makeObservable(this, {
      advertItems: observable,
      externalAdvertItems: observable,
      focusCoords: observable,
      isLoading: observable,
      advertsList: computed,
      externalAdvertsList: computed,
      setLoading: action,
      setAdvert: action,
      setExternalAdvert: action,
    });
  }

  get advertsList() {
    return values(this.advertItems);
  }

  get externalAdvertsList() {
    return values(this.externalAdvertItems);
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setFocusCoords(lat: number, lng: number) {
    this.focusCoords = [lat, lng];
  }

  setAdvert(item: TAdvertItemResponse) {
    const newAdvert = new AdvertItem(item);
    this.advertItems.set(item.id, newAdvert);
  }

  async fetchAdverts(query: string) {
    this.advertItems.clear();
    this.setLoading(true);
    const response = await AdvertService.fetchAdverts(query);

    if ('data' in response) {
      response.data.adverts.forEach((item) => {
        this.setAdvert(item);
      });

      this.setFocusCoords(response.data.focus_coords.lat, response.data.focus_coords.lng);

      const { meta } = response.data;

      this.setLastPage(meta?.last_page ?? 1);
      this.setCurrentPage(meta?.current_page ?? 1);
      this.setAmount(meta.total);
    } else {
      this.setLastPage(1);
      this.setCurrentPage(1);
      this.setAmount(0);
    }

    this.setLoading(false);
  }

  async fetchMapAdverts(advertIds: TAdvertsIds, page: string, isNeedToClear: boolean) {
    if (isNeedToClear) this.advertItems.clear();
    this.setLoading(true);

    const response = await AdvertService.fetchMapAdverts(advertIds, page);

    if ('data' in response) {
      response.data.adverts.forEach((item) => {
        this.setAdvert(item);
      });
      const { meta } = response.data;

      this.setLastPage(meta?.last_page ?? 1);
      this.setCurrentPage(meta?.current_page ?? 1);
      this.setAmount(meta.total);
    } else {
      this.setLastPage(1);
      this.setCurrentPage(1);
      this.setAmount(0);
    }

    this.setLoading(false);
  }

  async fetchHomePageAdverts() {
    this.advertItems.clear();
    this.externalAdvertItems.clear();
    this.setLoading(true);

    const responseRent = await AdvertService.fetchHomePageRentAdverts();
    const responseSale = await AdvertService.fetchHomePageSaleAdverts();

    if ('data' in responseRent && 'data' in responseSale) {
      responseRent.data.adverts?.forEach((item) => {
        this.setAdvert(item);
      });
      responseRent.data.external_adverts?.forEach((item) => {
        this.setExternalAdvert(item);
      });
      responseSale.data.adverts?.forEach((item) => {
        this.setAdvert(item);
      });
      responseSale.data.external_adverts?.forEach((item) => {
        this.setExternalAdvert(item);
      });
    }

    this.setLoading(false);
  }

  setExternalAdvert(item: TExternalAdvertItemResponse) {
    const newAdvert = new ExternalAdvertItem(item);
    this.externalAdvertItems.set(item.id, newAdvert);
  }

  async fetchExternalAdverts(query: string) {
    this.externalAdvertItems.clear();
    this.setLoading(true);
    const response = await AdvertService.fetchExternalAdverts(query);

    if ('data' in response) {
      response.data.external_adverts.forEach((item) => {
        this.setExternalAdvert(item);
      });

      this.setFocusCoords(response.data.focus_coords.lat, response.data.focus_coords.lng);

      const { meta } = response.data;

      this.setLastPage(meta?.last_page ?? 1);
      this.setCurrentPage(meta?.current_page ?? 1);
      this.setAmount(meta.total);
    } else {
      this.setLastPage(1);
      this.setCurrentPage(1);
      this.setAmount(0);
    }

    this.setLoading(false);
  }

  async fetchMapExtAdverts(advertIds: TExtAdvertsIds, page: string, isNeedToClear: boolean) {
    if (isNeedToClear) this.externalAdvertItems.clear();
    this.setLoading(true);

    const response = await AdvertService.fetchMapExtAdverts(advertIds, page);

    if ('data' in response) {
      response.data.external_adverts.forEach((item) => {
        this.setExternalAdvert(item);
      });

      const { meta } = response.data;

      this.setLastPage(meta?.last_page ?? 1);
      this.setCurrentPage(meta?.current_page ?? 1);
      this.setAmount(meta.total);
    } else {
      this.setLastPage(1);
      this.setCurrentPage(1);
      this.setAmount(0);
    }

    this.setLoading(false);
  }
}

import { FC } from 'react';

import { TIcon } from '../TIcon/TIcon';

const IcNews: FC<TIcon> = ({ className, onClick }) => {
  return (
    <svg className={className} onClick={onClick} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M38 13V35H10V13H38ZM38.4 9H9.6H6V12.3214V35.6786C6 35.6786 6 35.7857 6 39H38.4H42V35.6786V12.3214V9H38.4ZM14.3529 15.4286H18.7059V26.1429H14.3529V15.4286ZM33.6471 32.5714H14.3529V29.2857H33.6471V32.5714ZM33.6471 19.7143H22.9412V15.4286H33.6471V19.7143ZM33.6471 26.1429H22.9412V21.8571H33.6471V26.1429Z" fill="#53B374"/>
</svg>


  );
};

export default IcNews;
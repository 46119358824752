import makeRequest from '@/api/makeRequest';

import {
  GetLastPhotoReportsResponse,
  GetPhotoReportResponse,
  GetPhotoReportsResponse,
} from '@/types/api/IPhotoReportResponse';

class PhotoReportService {
  fetchPhotoReports(query: string) {
    return makeRequest<GetPhotoReportsResponse>({
      url: `api/photo_reports${query}`,
    });
  }
  fetchPhotoReport(id: string) {
    return makeRequest<GetPhotoReportResponse>({
      url: `api/photo_reports/${id}`,
    });
  }
  fetchHomePhotoReports() {
    return makeRequest<GetPhotoReportsResponse>({
      url: 'api/home_photo_reports',
    });
  }
  fetchLastPhotoReports() {
    return makeRequest<GetLastPhotoReportsResponse>({
      url: 'api/last_photo_reports',
    });
  }
}

export default new PhotoReportService();

import { FC } from 'react';

import styles from './NumberCard.module.scss';

interface NumberCard {
  number: string;
  text: string;
  helpText?: string;
}

const NumberCard: FC<NumberCard> = ({ number, text, helpText }) => {
  return (
    <div className={styles.numberCard}>
      <p className={styles.number}>{number}</p>
      <p className={styles.text}>{text}</p>
      {helpText && <p className={styles.helpText}>{helpText}</p>}
    </div>
  );
};

export default NumberCard;

// @ts-nocheck
import { makeAutoObservable } from 'mobx';

import { TQuestionCategoryResponse, TQuestionItemStore } from '@/types/entities/IQuestions';

export class QuestionCategoryItem implements TQuestionCategoryResponse {
  id: number;
  name: string;
  questions: TQuestionItemStore[];

  constructor(item?: TQuestionCategoryResponse) {
    if (item) {
      this.id = item.id;
      this.name = item.name;
      this.questions = item.questions;
    }
    makeAutoObservable(this);
  }
}

// @ts-nocheck
import { IPhotoReportStore } from '../types/stores/IPhotoReportStore';
import { IPhotoReportsStore } from '../types/stores/IPhotoReportsStore';
import { Account } from './account';
import { Advert } from './advert';
import { Adverts } from './adverts';
import { AgencyApartment } from './agencyApartment';
import { AgencyHouses } from './agencyHouses';
import { Auth } from './auth';
import { HousingIssues } from './housingIssues';
import { MapPoints } from './mapPoints';
import { NewsItem } from './newsItem';
import { NewsList } from './newsList';
import { PersonalAdverts } from './personalAdverts';
import { PhotoReport } from './photoReport';
import { PhotoReports } from './photoReports';
import { Questions } from './questions';
import { TradeInAdvert } from './tradeInAdvert';
import { TradeInAdverts } from './tradeInAdverts';
import { makeAutoObservable } from 'mobx';

import { TUserResponse } from '@/types/entities/IUser';
import { IAccountStore } from '@/types/stores/IAccountStore';
import { IAdvertStore } from '@/types/stores/IAdvertStore';
import { IAdvertsStore } from '@/types/stores/IAdvertsStore';
import { IAgencyApartmentStore } from '@/types/stores/IAgencyApartmentStore';
import { IAgencyHouses } from '@/types/stores/IAgencyHouses';
import { IAuthStore } from '@/types/stores/IAuthStore';
import { IHousingIssuesStore } from '@/types/stores/IHousingIssuesStore';
import { IMapPointsStore } from '@/types/stores/IMapPointsStore';
import { INewsItemStore } from '@/types/stores/INewsItemStore';
import { INewsListStore } from '@/types/stores/INewsListStore';
import { IPersonalAdvertsStore } from '@/types/stores/IPersonalAdvertsStore';
import { IQuestionsStore } from '@/types/stores/IQuestionsStore';
import { IRootStore } from '@/types/stores/IRootStore';
import { ITradeInAdvertStore } from '@/types/stores/ITradeInAdvertStore';
import { ITradeInAdvertsStore } from '@/types/stores/ITradeInAdvertsStore';

class Store implements IRootStore {
  auth: IAuthStore;
  account: IAccountStore | null;
  adverts: IAdvertsStore;
  personalAdverts: IPersonalAdvertsStore;
  advert: IAdvertStore;
  questions: IQuestionsStore;
  agencyHouses: IAgencyHouses;
  newsList: INewsListStore;
  newsItem: INewsItemStore;
  photoReports: IPhotoReportsStore;
  photoReport: IPhotoReportStore;
  mapPoints: IMapPointsStore;
  tradeInAdvert: ITradeInAdvertStore;
  tradeInAdverts: ITradeInAdvertsStore;
  agencyApartment: IAgencyApartmentStore;
  housingIssues: IHousingIssuesStore;

  constructor() {
    this.auth = new Auth(this);
    this.adverts = new Adverts();
    this.personalAdverts = new PersonalAdverts();
    this.advert = new Advert();
    this.questions = new Questions();
    this.agencyHouses = new AgencyHouses();
    this.newsItem = new NewsItem();
    this.newsList = new NewsList();
    this.photoReport = new PhotoReport();
    this.photoReports = new PhotoReports();
    this.mapPoints = new MapPoints();
    this.tradeInAdvert = new TradeInAdvert();
    this.tradeInAdverts = new TradeInAdverts();
    this.agencyApartment = new AgencyApartment();
    this.housingIssues = new HousingIssues();

    makeAutoObservable(this);
  }

  setAccountStore(account: TUserResponse | null) {
    if (account === null) {
      this.account = null;
      this.auth.setAuth(false);

      return;
    }

    this.account = new Account(this, account);
    this.auth.setAuth(true);
  }
}

const store = new Store();
export default store;

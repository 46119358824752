import { FC } from 'react';

import { Loader } from '@/components/UI';
import { PageWrapper } from '../Containers';

import styles from './LoaderPage.module.scss';
import NextWrapper from '@/components/layouts/Containers/NextWrapper/NextWrapper';

const LoaderPage: FC = () => {
  const meta = {
    title: 'Загрузка...',
  };

  return (
    <PageWrapper mainStyles={styles.main} meta={meta}>
      {/* <NextWrapper meta={meta}></NextWrapper> */}
      <Loader />
    </PageWrapper>
  );
};

export default LoaderPage;

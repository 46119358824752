
import { AuthService } from '@/api';
import { makeAutoObservable } from 'mobx';

import { APIError } from '@/types/api/IAPI';
import { TRegistrationAccount } from '@/types/entities/IRegistration';
import { TUserLogin } from '@/types/entities/IUser';
import { IAuthStore } from '@/types/stores/IAuthStore';
import { IRootStore } from '@/types/stores/IRootStore';

export class Auth implements IAuthStore {
  rootStore: IRootStore;
  isAuth: boolean | null;
  error: string | null;
  isLoading: boolean;

  constructor(rootStore: IRootStore) {
    this.rootStore = rootStore;
    this.isAuth = null;
    this.error = null;
    this.isLoading = false;

    makeAutoObservable(this);
    this.checkAuth();
  }

  setAuth(isAuth: boolean) {
    this.isAuth = isAuth;
  }

  setError(error: string | null) {
    this.error = error;
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  serializeRegistrationErrors(errors: APIError['errors']) {
    return {
      email: errors.email,
      password: errors.password,
      passwordConfirmation: errors.password_confirmation,
      firstName: errors.first_name,
      lastName: errors.last_name,
      patronymicName: errors.patronymic_name,
      birthday: errors.birthday,
      phone: errors.phone,
    };
  }

  async registration(account: TRegistrationAccount) {
    const response = await AuthService.registration(account);

    if ('data' in response) {
      const data = response.data;

      localStorage.setItem('auth_token', data.token);
      this.setAuth(true);
    }

    return response;
  }

  async authorization(account: TUserLogin) {
    const response = await AuthService.authorization(account);

    if ('data' in response) {
      const data = response.data;
      localStorage.setItem('auth_token', data.token);
      this.setError(null);
      this.setAuth(true);

      this.checkAuth();

      return response;
    }

    if (response.status === 401) this.setError('Неправильный логин или пароль');
    return response;
  }

  logout() {
    AuthService.logout();
    localStorage.clear();
    this.rootStore.setAccountStore(null);
  }

  async checkAuth() {
    this.setLoading(true);

    const response = await AuthService.checkAuth();

    if (response === false) {
      return this.setAuth(false);
    }
    if(response !== undefined){
      if ('data' in response ) {
        this.setAuth(true);

        const user = response.data.user;
        this.rootStore.setAccountStore(user);
      } else {
        this.setAuth(false);
        localStorage.clear();
      }
    }


    this.setLoading(false);
  }
}

export const staticLinks = {
  adverts: '/vnutrennie-obyavleniya',
  externalAdverts: '/obyavleniya-drugih-saitov',
  registration: '/registraciya',
  authorization: '/avtorizaciya',
  personalAdverts: '/moi-obyavleniya',
  saleAdverts: '/prodazha-kvartir',
  rentAdverts: '/arenda-kvartir',
  createAdvert: '/sozdanie-obyavleniya',
  editAdvert: '/redaktirovanie-obyavleniya/:id',
  editProfile: '/redaktirovanie-profilya',
  advert: '/obyavleniya/:id',
  notFound: '/404',
  mapAdverts: '/obyavleniya-na-karte',
  mapExternalAdverts: '/obyavleniya-drugih-saitov-na-karte',
  homeBuyout: '/vikup-arendnogo-zhilya',
  documents: '/documenty',
  aboutCompany: '/o-nashei-kompanii',
  easternMortgage: '/dalnevostochnaya-ipoteka',
  stepByStep: '/shag-za-shagom',
  saleImmovable: '/prodazha-kommercheskoi-nedvizhimosti',
  news: '/novosti',
  newsItem: '/novosti/:id',
  photoReports: '/photootchety',
  photoReport: '/photootchety/:id',
  main: '/',
  questions: '/voprosy',
  homeApplication: '/zayavka-na-uluchsenie-zhilichnyh-usloviy',
  homeQRs: '/reshenie-zhilichnogo-voprosa',
  contacts: '/kontakty',
  mortgageRules: '/pravila-vydachi-ipotechnogo-zaima',
  mortgage: '/ipoteka',
  horizont: 'https://gorizont65.ru/?etext=2202.BxogXA8ZzFmLCs_E9zWqf_5Fcfjb3liL8Cy1GhiHNUEI5e3W17OrQddonBsHa3u3VIKjV9HwmGmYiPe_6evV3nFtbHRuZ2tkZWJ5a3ZsbnU.3fae141d36dc413ee316635abbce7a471f227036&yclid=4680291613693968383',
  inWork: '/v-rabote',
  dataPolicy: '/politika-obrabotki-personalnyh-dannyh',
  bookingHouses: '/doma-agentstva',
  bookingApartments: '/doma-agentstva/:id',
  developerPage: '/zastroischiki/:id',
  developerNews: '/novosti-zastroischikov/:devId-:newsId',
  tradeInAdvert: '/shag-za-shagom/obyavleniya/:id',
  dataPolicyStepByStep: '/shag-za-shagom/politika-obrabotki-personalnyh-dannyh',
  agencyApartment: '/doma-agentstva/:parentId/kvartiry-agentstva/:id',
  apartmentInInstallments: '/kvartira-v-rassrochku',
  commercialAdvert: '/biznes-obyavleniya/:id',
  rentSearch: '/pomosch-v-poiske-arendnogo-zhilya',
  housingPrivileges: '/zhilye-po-lgotnoy-stoimosti',
  rentForm: '/ankety-arendy',
};

export const dynamicLinks = {
  advert: (id: number) => `/obyavleniya/${id}`,
  tradeInAdvert: (id: number) => `/shag-za-shagom/obyavleniya/${id}`,
  editAdvert: (id: number) => `/redaktirovanie-obyavleniya/${id}`,
  bookingApartments: (id: number) => `/doma-agentstva/${id}`,
  newsItem: (id: number) => `/novosti/${id}`,
  photoReport: (id: number) => `/photootchety/${id}`,
  developerPage: (id: number) => `/zastroischiki/${id}`,
  commercialAdvert: (id: number) => `/biznes-obyavleniya/${id}`,
};

import { makeAutoObservable, toJS } from 'mobx';

import AgencyHousesService from '@/api/rest/AgencyHousesService';

import {
  TAgencyApartmentCamelize,
  TAgencyApartmentResponse,
} from '@/types/entities/IAgencyApartment';
import { TAdvertPhotoCamelize, TAdvertPhotoResponse } from '@/types/general/unions';
import { IAgencyApartmentStore } from '@/types/stores/IAgencyApartmentStore';

export class AgencyApartment implements IAgencyApartmentStore {
  agencyApartment= {} as  TAgencyApartmentCamelize;
  isLoading = true;

  constructor() {
    makeAutoObservable(this);
  }

  get profile() {
    return toJS(this.agencyApartment);
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setAgencyApartment(agencyApartment: TAgencyApartmentCamelize) {
    this.agencyApartment = agencyApartment;
  }

  serialize(item: TAgencyApartmentResponse) {
    return {
      id: item.id,
      agencyHouseAddress: item.agency_house_address,
      apartmentNumber: item.apartment_number,
      balcony: item.balcony,
      bathroom: item.bathroom,
      builtYear: item.built_year,
      ceilingHeight: item.ceiling_height,
      description: item.description,
      floor: item.floor,
      isHaveGas: item.is_have_gas,
      isHaveInternet: item.is_have_internet,
      isHavePhone: item.is_have_phone,
      isHaveSecurity: item.is_have_security,
      isHaveTv: item.is_have_tv,
      isLoyaltyPrograms: item.is_loyalty_programs,
      kitchenArea: item.kitchen_area,
      layout: item.layout,
      livingArea: item.living_area,
      parking: item.parking,
      passengerLift: item.passenger_lift,
      photos: this.serializePhotos(item.photos),
      price: item.price,
      repair: item.repair,
      roomsCount: item.rooms_count,
      serviceLift: item.service_lift,
      sideOfTheWorld: item.side_of_the_world,
      status: item.status,
      totalArea: item.total_area,
      numberOfFloors: item.number_of_floors,
      wallMaterial: item.wall_material,
    };
  }

  serializePhotos(photos?: TAdvertPhotoResponse[]): TAdvertPhotoCamelize[] {
    return (
      photos?.map((photo) => {
        return {
          id: photo.id,
          description: photo.description,
          url: photo.url,
          isMain: photo.is_main,
          thumbUrl: photo.thumb_url,
        };
      }) || ([] as TAdvertPhotoCamelize[])
    );
  }

  async fetchAgencyApartment(parentId: string, id: string) {
    this.setLoading(true);

    const response = await AgencyHousesService.fetchAgencyApartment(parentId, id);

    if ('data' in response) {
      this.setAgencyApartment(this.serialize(response.data.agency_apartment));
    }

    this.setLoading(false);
  }
}

import { FC } from 'react';

import { ImageWithBlur } from '@/components/UI';

import { dynamicLinks } from '@/config/routingLinks';
import { formatToSimpleView } from '@/utils/dateFunctions';

import { TAdvertItemCamelize } from '@/types/entities/IAdvert';

import styles from './FlatCard.module.scss';
import IcPlug from '@/components/Icons/IcPlug';
import Link from 'next/link';

interface FlatCardProps {
  item: TAdvertItemCamelize;
}

const FlatCard: FC<FlatCardProps> = ({ item }) => {
  const floor = item?.floor === -1 ? 'Цокольный этаж' : `${item?.floor}/${item.totalFloors} этаж`;

  const formattedPrice = Intl.NumberFormat('ru-RU').format(item?.price);

  const isCommercial = item.propertyType === 'Коммерческая недвижимость';

  const price =
    item.dealType === 'Продажа'
      ? formattedPrice + ' ₽'
      : item.rentType === 'Длительно' || isCommercial
      ? formattedPrice + ' ₽ / мес.'
      : formattedPrice + ' ₽ / сут.';

  const mortgage = item.isPossibleMortgage ? 'Возможна ипотека' : '';

  const getAdditionalPriceData =
    item.dealType === 'Продажа'
      ? mortgage
      : isCommercial
      ? ''
      : item.deposit !== 0 && item.commission !== 0
      ? item.deposit + ' ₽ Залог, ' + item.commission + ' ₽ Комиссия'
      : item.deposit !== 0 && item.commission === 0
      ? item.deposit + ' ₽ Залог, ' + 'Без комиссии'
      : item.deposit === 0 && item.commission !== 0
      ? 'Без залога, ' + item.commission + ' ₽ Комиссия'
      : 'Без залога, Без комиссии';

  const link =
    item.propertyType === 'Квартира'
      ? dynamicLinks.advert(item?.id)
      : dynamicLinks.commercialAdvert(item?.id);

  const plotArea = item?.plotArea ? `, ${item?.plotArea} ${item?.measuringPlotArea}` : '';

  const getAdvertName = () => {
    if (isCommercial) {
      return `${item.appointment}, ${item.totalArea} м², ${floor}${plotArea}`;
    }
    return `${item.roomsCount}-к. квартира ${item.totalArea} м² ${floor}`;
  };

  return (
    <div className={styles.flatCard}>
      <Link className={styles.flatLink} href={link} target='_blank' rel='noreferrer'></Link>
      <div className={styles.imgWrapper}>
        {item?.photo ? (
          <>
            <ImageWithBlur url={item.photo?.thumbUrl} />
          </>
        ) : (
          <IcPlug />
        )}

        {item.isAgencyAdvert && <span>От СИА</span>}
      </div>

      <div className={styles.body}>
        <p className={styles.time}>{formatToSimpleView(item?.updatedAt) ?? 'Дата не указана'}</p>

        <div className={styles.about}>
          {item.isApproved && <span className={styles.status}>Проверено агентством</span>}
          {item.isOwner && !item.isAgencyAdvert && (
            <span className={styles.status}>От собственника</span>
          )}

          <div className={styles.info}>{getAdvertName()}</div>

          <div className={styles.options}>
            <span className={styles.price}>{price}</span>
            <div className={styles.additional}>
              <span>{getAdditionalPriceData}</span>
            </div>
          </div>

          <p className={styles.address}>{item.address}</p>
        </div>
      </div>
    </div>
  );
};

export default FlatCard;

// @ts-nocheck
import { useRouter } from 'next/router';
import { Suspense, useEffect, useState } from 'react';

import { Footer } from '..';
import { ToastMessage } from '@/components/Logic';
import LoaderPage from '../LoaderPage/LoaderPage';
import { useStore } from '@/hooks/useStore';
import { Header } from '../Header';

const Layout = ({ children }) => {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const [pathname, setPathname] = useState(router.pathname);

  useEffect(() => {
    const handleRouteChange = () => {
      setIsLoading(true);
    };
    const handleRouteChangeComplete = () => {
      setIsLoading(false);
      setPathname(router.pathname);
    };
    router.events.on('routeChangeStart', handleRouteChange);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);
    router.events.on('routeChangeError', handleRouteChangeComplete);
  }, [router]);

  return (
    <>
      <ToastMessage />
      <Header />
      {isLoading && router.pathname !== '/StepByStep' && router.pathname !== '/Adverts' ? (
        <LoaderPage />
      ) : (
        children
      )}
      {router.pathname !== '/AdvertsMap' && <Footer />}
    </>
  );
};

export default Layout;

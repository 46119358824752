import axios from 'axios';

import { APIResponse, TMakeRequestParams } from '@/types/api/IAPI';

const makeRequest = <Type>({
  url = '/',
  method = 'GET',
  authToken = false,
  headers = {},
  params = {},
  data = {},
}: TMakeRequestParams): APIResponse<Type> => {
  url = `${process.env.NEXT_PUBLIC_BACKEND_URL}/${url}`;

  if (authToken) {
    if (typeof window !== 'undefined'){
      const token = localStorage.getItem('auth_token');
      headers.authorization = `Bearer ${token}`;
    }
  }

  return axios
    .request<Type>({
      url,
      method,
      headers,
      params,
      data,
    })
    .catch((errors) => {
      const responseErrors = errors.response?.data?.errors;
      const status = errors?.response?.status as number;
      const meta = errors?.response?.data?.meta;

      return { errors: responseErrors, status };
    });
};

export default makeRequest;

import { FC, ReactNode, useEffect } from 'react';

import styles from './MainWrapper.module.scss';

interface MainWrapperProps {
  children: ReactNode;
}

const MainWrapper: FC<MainWrapperProps> = ({ children }) => {
  return <div className={styles.mainWrapper}>{children}</div>;
};

export default MainWrapper;

import { FC } from 'react';

import { TIcon } from '../TIcon/TIcon';

const IcBuildings: FC<TIcon> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g opacity='0.5'>
        <path d='M34.6923 17.6667H30.5385V13.4444H34.6923V17.6667Z' fill='#53B374' />
        <path d='M34.6923 26.1111H30.5385V21.8889H34.6923V26.1111Z' fill='#53B374' />
        <path d='M34.6923 34.5556H30.5385V30.3333H34.6923V34.5556Z' fill='#53B374' />
        <path
          d='M30.5385 38.7778V43H43V5H16V9.22222H20.1538H38.8462V38.7778H30.5385Z'
          fill='#53B374'
        />
      </g>
      <g opacity='0.5'>
        <path d='M19.5385 23H16.3077V19.6667H19.5385V23Z' fill='#53B374' />
        <path d='M19.5385 29.6667H16.3077V26.3333H19.5385V29.6667Z' fill='#53B374' />
        <path d='M19.5385 36.3333H16.3077V33H19.5385V36.3333Z' fill='#53B374' />
        <path
          d='M16.3077 39.6667V43H26V13H5V16.25L8.23077 18.6667V16.3333H22.7692V39.6667H16.3077Z'
          fill='#53B374'
        />
      </g>
      <g opacity='0.5'>
        <path d='M19.5385 23H16.3077V19.6667H19.5385V23Z' fill='#53B374' />
        <path d='M19.5385 29.6667H16.3077V26.3333H19.5385V29.6667Z' fill='#53B374' />
        <path d='M19.5385 36.3333H16.3077V33H19.5385V36.3333Z' fill='#53B374' />
        <path
          d='M16.3077 39.6667V43H26V13H5V16.25L8.23077 18.6667V16.3333H22.7692V39.6667H16.3077Z'
          fill='#53B374'
        />
      </g>
      <g opacity='0.5'>
        <path d='M19.5385 23H16.3077V19.6667H19.5385V23Z' fill='#53B374' />
        <path d='M19.5385 29.6667H16.3077V26.3333H19.5385V29.6667Z' fill='#53B374' />
        <path d='M19.5385 36.3333H16.3077V33H19.5385V36.3333Z' fill='#53B374' />
        <path
          d='M16.3077 39.6667V43H26V13H5V16.25L8.23077 18.6667V16.3333H22.7692V39.6667H16.3077Z'
          fill='#53B374'
        />
      </g>
      <g opacity='0.5'>
        <path d='M19.5385 23H16.3077V19.6667H19.5385V23Z' fill='#53B374' />
        <path d='M19.5385 29.6667H16.3077V26.3333H19.5385V29.6667Z' fill='#53B374' />
        <path d='M19.5385 36.3333H16.3077V33H19.5385V36.3333Z' fill='#53B374' />
        <path
          d='M16.3077 39.6667V43H26V13H5V16.25L8.23077 18.6667V16.3333H22.7692V39.6667H16.3077Z'
          fill='#53B374'
        />
      </g>
      <g opacity='0.5'>
        <path d='M19.5385 23H16.3077V19.6667H19.5385V23Z' fill='#53B374' />
        <path d='M19.5385 29.6667H16.3077V26.3333H19.5385V29.6667Z' fill='#53B374' />
        <path d='M19.5385 36.3333H16.3077V33H19.5385V36.3333Z' fill='#53B374' />
        <path
          d='M16.3077 39.6667V43H26V13H5V16.25L8.23077 18.6667V16.3333H22.7692V39.6667H16.3077Z'
          fill='#53B374'
        />
      </g>
      <g opacity='0.5'>
        <path d='M19.5385 23H16.3077V19.6667H19.5385V23Z' fill='#53B374' />
        <path d='M19.5385 29.6667H16.3077V26.3333H19.5385V29.6667Z' fill='#53B374' />
        <path d='M19.5385 36.3333H16.3077V33H19.5385V36.3333Z' fill='#53B374' />
        <path
          d='M16.3077 39.6667V43H26V13H5V16.25L8.23077 18.6667V16.3333H22.7692V39.6667H16.3077Z'
          fill='#53B374'
        />
      </g>
      <g opacity='0.5'>
        <path d='M19.5385 23H16.3077V19.6667H19.5385V23Z' fill='#53B374' />
        <path d='M19.5385 29.6667H16.3077V26.3333H19.5385V29.6667Z' fill='#53B374' />
        <path d='M19.5385 36.3333H16.3077V33H19.5385V36.3333Z' fill='#53B374' />
        <path
          d='M16.3077 39.6667V43H26V13H5V16.25L8.23077 18.6667V16.3333H22.7692V39.6667H16.3077Z'
          fill='#53B374'
        />
      </g>
      <g opacity='0.5'>
        <path d='M19.5385 23H16.3077V19.6667H19.5385V23Z' fill='#53B374' />
        <path d='M19.5385 29.6667H16.3077V26.3333H19.5385V29.6667Z' fill='#53B374' />
        <path d='M19.5385 36.3333H16.3077V33H19.5385V36.3333Z' fill='#53B374' />
        <path
          d='M16.3077 39.6667V43H26V13H5V16.25L8.23077 18.6667V16.3333H22.7692V39.6667H16.3077Z'
          fill='#53B374'
        />
      </g>
      <g opacity='0.5'>
        <path d='M19.5385 23H16.3077V19.6667H19.5385V23Z' fill='#53B374' />
        <path d='M19.5385 29.6667H16.3077V26.3333H19.5385V29.6667Z' fill='#53B374' />
        <path d='M19.5385 36.3333H16.3077V33H19.5385V36.3333Z' fill='#53B374' />
        <path
          d='M16.3077 39.6667V43H26V13H5V16.25L8.23077 18.6667V16.3333H22.7692V39.6667H16.3077Z'
          fill='#53B374'
        />
      </g>
      <g opacity='0.5'>
        <path d='M19.5385 23H16.3077V19.6667H19.5385V23Z' fill='#53B374' />
        <path d='M19.5385 29.6667H16.3077V26.3333H19.5385V29.6667Z' fill='#53B374' />
        <path d='M19.5385 36.3333H16.3077V33H19.5385V36.3333Z' fill='#53B374' />
        <path
          d='M16.3077 39.6667V43H26V13H5V16.25L8.23077 18.6667V16.3333H22.7692V39.6667H16.3077Z'
          fill='#53B374'
        />
      </g>
      <g opacity='0.5'>
        <path d='M19.5385 23H16.3077V19.6667H19.5385V23Z' fill='#53B374' />
        <path d='M19.5385 29.6667H16.3077V26.3333H19.5385V29.6667Z' fill='#53B374' />
        <path d='M19.5385 36.3333H16.3077V33H19.5385V36.3333Z' fill='#53B374' />
        <path
          d='M16.3077 39.6667V43H26V13H5V16.25L8.23077 18.6667V16.3333H22.7692V39.6667H16.3077Z'
          fill='#53B374'
        />
      </g>
      <g opacity='0.5'>
        <path d='M19.5385 23H16.3077V19.6667H19.5385V23Z' fill='#53B374' />
        <path d='M19.5385 29.6667H16.3077V26.3333H19.5385V29.6667Z' fill='#53B374' />
        <path d='M19.5385 36.3333H16.3077V33H19.5385V36.3333Z' fill='#53B374' />
        <path
          d='M16.3077 39.6667V43H26V13H5V16.25L8.23077 18.6667V16.3333H22.7692V39.6667H16.3077Z'
          fill='#53B374'
        />
      </g>
      <g opacity='0.5'>
        <path d='M19.5385 23H16.3077V19.6667H19.5385V23Z' fill='#53B374' />
        <path d='M19.5385 29.6667H16.3077V26.3333H19.5385V29.6667Z' fill='#53B374' />
        <path d='M19.5385 36.3333H16.3077V33H19.5385V36.3333Z' fill='#53B374' />
        <path
          d='M16.3077 39.6667V43H26V13H5V16.25L8.23077 18.6667V16.3333H22.7692V39.6667H16.3077Z'
          fill='#53B374'
        />
      </g>
      <g opacity='0.5'>
        <path d='M19.5385 23H16.3077V19.6667H19.5385V23Z' fill='#53B374' />
        <path d='M19.5385 29.6667H16.3077V26.3333H19.5385V29.6667Z' fill='#53B374' />
        <path d='M19.5385 36.3333H16.3077V33H19.5385V36.3333Z' fill='#53B374' />
        <path
          d='M16.3077 39.6667V43H26V13H5V16.25L8.23077 18.6667V16.3333H22.7692V39.6667H16.3077Z'
          fill='#53B374'
        />
      </g>
      <g opacity='0.5'>
        <path
          d='M11.4615 33L14.6923 33L14.6923 36.3333L11.4615 36.3333L11.4615 33Z'
          fill='#53B374'
        />
        <path
          d='M11.4615 26.3333L14.6923 26.3333L14.6923 29.6667L11.4615 29.6667L11.4615 26.3333Z'
          fill='#53B374'
        />
        <path
          d='M11.4615 19.6667L14.6923 19.6667L14.6923 23L11.4615 23L11.4615 19.6667Z'
          fill='#53B374'
        />
        <path
          d='M14.6923 16.3333L14.6923 13L5 13L5 43L26 43L26 39.75L22.7692 37.3333L22.7692 39.6667L8.23077 39.6667L8.23077 16.3333L14.6923 16.3333Z'
          fill='#53B374'
        />
      </g>
      <g opacity='0.5'>
        <path
          d='M11.4615 33L14.6923 33L14.6923 36.3333L11.4615 36.3333L11.4615 33Z'
          fill='#53B374'
        />
        <path
          d='M11.4615 26.3333L14.6923 26.3333L14.6923 29.6667L11.4615 29.6667L11.4615 26.3333Z'
          fill='#53B374'
        />
        <path
          d='M11.4615 19.6667L14.6923 19.6667L14.6923 23L11.4615 23L11.4615 19.6667Z'
          fill='#53B374'
        />
        <path
          d='M14.6923 16.3333L14.6923 13L5 13L5 43L26 43L26 39.75L22.7692 37.3333L22.7692 39.6667L8.23077 39.6667L8.23077 16.3333L14.6923 16.3333Z'
          fill='#53B374'
        />
      </g>
      <g opacity='0.5'>
        <path
          d='M11.4615 33L14.6923 33L14.6923 36.3333L11.4615 36.3333L11.4615 33Z'
          fill='#53B374'
        />
        <path
          d='M11.4615 26.3333L14.6923 26.3333L14.6923 29.6667L11.4615 29.6667L11.4615 26.3333Z'
          fill='#53B374'
        />
        <path
          d='M11.4615 19.6667L14.6923 19.6667L14.6923 23L11.4615 23L11.4615 19.6667Z'
          fill='#53B374'
        />
        <path
          d='M14.6923 16.3333L14.6923 13L5 13L5 43L26 43L26 39.75L22.7692 37.3333L22.7692 39.6667L8.23077 39.6667L8.23077 16.3333L14.6923 16.3333Z'
          fill='#53B374'
        />
      </g>
      <g opacity='0.5'>
        <path
          d='M11.4615 33L14.6923 33L14.6923 36.3333L11.4615 36.3333L11.4615 33Z'
          fill='#53B374'
        />
        <path
          d='M11.4615 26.3333L14.6923 26.3333L14.6923 29.6667L11.4615 29.6667L11.4615 26.3333Z'
          fill='#53B374'
        />
        <path
          d='M11.4615 19.6667L14.6923 19.6667L14.6923 23L11.4615 23L11.4615 19.6667Z'
          fill='#53B374'
        />
        <path
          d='M14.6923 16.3333L14.6923 13L5 13L5 43L26 43L26 39.75L22.7692 37.3333L22.7692 39.6667L8.23077 39.6667L8.23077 16.3333L14.6923 16.3333Z'
          fill='#53B374'
        />
      </g>
      <g opacity='0.5'>
        <path
          d='M11.4615 33L14.6923 33L14.6923 36.3333L11.4615 36.3333L11.4615 33Z'
          fill='#53B374'
        />
        <path
          d='M11.4615 26.3333L14.6923 26.3333L14.6923 29.6667L11.4615 29.6667L11.4615 26.3333Z'
          fill='#53B374'
        />
        <path
          d='M11.4615 19.6667L14.6923 19.6667L14.6923 23L11.4615 23L11.4615 19.6667Z'
          fill='#53B374'
        />
        <path
          d='M14.6923 16.3333L14.6923 13L5 13L5 43L26 43L26 39.75L22.7692 37.3333L22.7692 39.6667L8.23077 39.6667L8.23077 16.3333L14.6923 16.3333Z'
          fill='#53B374'
        />
      </g>
      <g opacity='0.5'>
        <path
          d='M11.4615 33L14.6923 33L14.6923 36.3333L11.4615 36.3333L11.4615 33Z'
          fill='#53B374'
        />
        <path
          d='M11.4615 26.3333L14.6923 26.3333L14.6923 29.6667L11.4615 29.6667L11.4615 26.3333Z'
          fill='#53B374'
        />
        <path
          d='M11.4615 19.6667L14.6923 19.6667L14.6923 23L11.4615 23L11.4615 19.6667Z'
          fill='#53B374'
        />
        <path
          d='M14.6923 16.3333L14.6923 13L5 13L5 43L26 43L26 39.75L22.7692 37.3333L22.7692 39.6667L8.23077 39.6667L8.23077 16.3333L14.6923 16.3333Z'
          fill='#53B374'
        />
      </g>
      <g opacity='0.5'>
        <path
          d='M11.4615 33L14.6923 33L14.6923 36.3333L11.4615 36.3333L11.4615 33Z'
          fill='#53B374'
        />
        <path
          d='M11.4615 26.3333L14.6923 26.3333L14.6923 29.6667L11.4615 29.6667L11.4615 26.3333Z'
          fill='#53B374'
        />
        <path
          d='M11.4615 19.6667L14.6923 19.6667L14.6923 23L11.4615 23L11.4615 19.6667Z'
          fill='#53B374'
        />
        <path
          d='M14.6923 16.3333L14.6923 13L5 13L5 43L26 43L26 39.75L22.7692 37.3333L22.7692 39.6667L8.23077 39.6667L8.23077 16.3333L14.6923 16.3333Z'
          fill='#53B374'
        />
      </g>
      <g opacity='0.5'>
        <path
          d='M11.4615 33L14.6923 33L14.6923 36.3333L11.4615 36.3333L11.4615 33Z'
          fill='#53B374'
        />
        <path
          d='M11.4615 26.3333L14.6923 26.3333L14.6923 29.6667L11.4615 29.6667L11.4615 26.3333Z'
          fill='#53B374'
        />
        <path
          d='M11.4615 19.6667L14.6923 19.6667L14.6923 23L11.4615 23L11.4615 19.6667Z'
          fill='#53B374'
        />
        <path
          d='M14.6923 16.3333L14.6923 13L5 13L5 43L26 43L26 39.75L22.7692 37.3333L22.7692 39.6667L8.23077 39.6667L8.23077 16.3333L14.6923 16.3333Z'
          fill='#53B374'
        />
      </g>
      <g opacity='0.5'>
        <path
          d='M11.4615 33L14.6923 33L14.6923 36.3333L11.4615 36.3333L11.4615 33Z'
          fill='#53B374'
        />
        <path
          d='M11.4615 26.3333L14.6923 26.3333L14.6923 29.6667L11.4615 29.6667L11.4615 26.3333Z'
          fill='#53B374'
        />
        <path
          d='M11.4615 19.6667L14.6923 19.6667L14.6923 23L11.4615 23L11.4615 19.6667Z'
          fill='#53B374'
        />
        <path
          d='M14.6923 16.3333L14.6923 13L5 13L5 43L26 43L26 39.75L22.7692 37.3333L22.7692 39.6667L8.23077 39.6667L8.23077 16.3333L14.6923 16.3333Z'
          fill='#53B374'
        />
      </g>
      <g opacity='0.5'>
        <path
          d='M11.4615 33L14.6923 33L14.6923 36.3333L11.4615 36.3333L11.4615 33Z'
          fill='#53B374'
        />
        <path
          d='M11.4615 26.3333L14.6923 26.3333L14.6923 29.6667L11.4615 29.6667L11.4615 26.3333Z'
          fill='#53B374'
        />
        <path
          d='M11.4615 19.6667L14.6923 19.6667L14.6923 23L11.4615 23L11.4615 19.6667Z'
          fill='#53B374'
        />
        <path
          d='M14.6923 16.3333L14.6923 13L5 13L5 43L26 43L26 39.75L22.7692 37.3333L22.7692 39.6667L8.23077 39.6667L8.23077 16.3333L14.6923 16.3333Z'
          fill='#53B374'
        />
      </g>
      <g opacity='0.5'>
        <path
          d='M11.4615 33L14.6923 33L14.6923 36.3333L11.4615 36.3333L11.4615 33Z'
          fill='#53B374'
        />
        <path
          d='M11.4615 26.3333L14.6923 26.3333L14.6923 29.6667L11.4615 29.6667L11.4615 26.3333Z'
          fill='#53B374'
        />
        <path
          d='M11.4615 19.6667L14.6923 19.6667L14.6923 23L11.4615 23L11.4615 19.6667Z'
          fill='#53B374'
        />
        <path
          d='M14.6923 16.3333L14.6923 13L5 13L5 43L26 43L26 39.75L22.7692 37.3333L22.7692 39.6667L8.23077 39.6667L8.23077 16.3333L14.6923 16.3333Z'
          fill='#53B374'
        />
      </g>
      <g opacity='0.5'>
        <path
          d='M11.4615 33L14.6923 33L14.6923 36.3333L11.4615 36.3333L11.4615 33Z'
          fill='#53B374'
        />
        <path
          d='M11.4615 26.3333L14.6923 26.3333L14.6923 29.6667L11.4615 29.6667L11.4615 26.3333Z'
          fill='#53B374'
        />
        <path
          d='M11.4615 19.6667L14.6923 19.6667L14.6923 23L11.4615 23L11.4615 19.6667Z'
          fill='#53B374'
        />
        <path
          d='M14.6923 16.3333L14.6923 13L5 13L5 43L26 43L26 39.75L22.7692 37.3333L22.7692 39.6667L8.23077 39.6667L8.23077 16.3333L14.6923 16.3333Z'
          fill='#53B374'
        />
      </g>
      <g opacity='0.5'>
        <path
          d='M11.4615 33L14.6923 33L14.6923 36.3333L11.4615 36.3333L11.4615 33Z'
          fill='#53B374'
        />
        <path
          d='M11.4615 26.3333L14.6923 26.3333L14.6923 29.6667L11.4615 29.6667L11.4615 26.3333Z'
          fill='#53B374'
        />
        <path
          d='M11.4615 19.6667L14.6923 19.6667L14.6923 23L11.4615 23L11.4615 19.6667Z'
          fill='#53B374'
        />
        <path
          d='M14.6923 16.3333L14.6923 13L5 13L5 43L26 43L26 39.75L22.7692 37.3333L22.7692 39.6667L8.23077 39.6667L8.23077 16.3333L14.6923 16.3333Z'
          fill='#53B374'
        />
      </g>
      <g opacity='0.5'>
        <path
          d='M11.4615 33L14.6923 33L14.6923 36.3333L11.4615 36.3333L11.4615 33Z'
          fill='#53B374'
        />
        <path
          d='M11.4615 26.3333L14.6923 26.3333L14.6923 29.6667L11.4615 29.6667L11.4615 26.3333Z'
          fill='#53B374'
        />
        <path
          d='M11.4615 19.6667L14.6923 19.6667L14.6923 23L11.4615 23L11.4615 19.6667Z'
          fill='#53B374'
        />
        <path
          d='M14.6923 16.3333L14.6923 13L5 13L5 43L26 43L26 39.75L22.7692 37.3333L22.7692 39.6667L8.23077 39.6667L8.23077 16.3333L14.6923 16.3333Z'
          fill='#53B374'
        />
      </g>
    </svg>
  );
};

export default IcBuildings;

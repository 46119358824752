import { FC } from 'react';

import { TIcon } from './TIcon/TIcon';

const IcProfile: FC<TIcon> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width='42'
      height='42'
      viewBox='0 0 42 42'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M25 16C25 18.2091 23.2091 20 21 20C18.7909 20 17 18.2091 17 16C17 13.7909 18.7909 12 21 12C23.2091 12 25 13.7909 25 16ZM23 16C23 17.1046 22.1046 18 21 18C19.8954 18 19 17.1046 19 16C19 14.8954 19.8954 14 21 14C22.1046 14 23 14.8954 23 16Z'
        fill='#B4B4B9'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M29 26.1666C29 25.7333 28.8642 25.3074 28.5815 24.979C26.7477 22.8488 24.0313 21.5 21 21.5C17.9687 21.5 15.2523 22.8488 13.4185 24.979C13.1358 25.3074 13 25.7333 13 26.1666V28C13 29.1046 13.8954 30 15 30H27C28.1046 30 29 29.1046 29 28V26.1666ZM27 26.2083C25.5313 24.5445 23.3887 23.5 21 23.5C18.6113 23.5 16.4687 24.5445 15 26.2083V28H27V26.2083ZM15.0002 26.1622C15.0002 26.1622 15.0002 26.1622 15.0002 26.1623C15.0002 26.1624 15.0002 26.1625 15.0001 26.1627C15.0001 26.1625 15.0002 26.1623 15.0002 26.1622Z'
        fill='#B4B4B9'
      />
      <rect x='0.5' y='0.5' width='41' height='41' rx='20.5' stroke='#B4B4B9' />
    </svg>
  );
};

export default IcProfile;

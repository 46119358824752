import { FC } from 'react';

import { TIcon } from './TIcon/TIcon';

const IcHomeQuest: FC<TIcon> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2003_15657)'>
        <path
          d='M12 38H18H30H36V20L24 11L12 20V38ZM8 42V18L24 6L40 18V42H26H22H8Z'
          fill='#53B374'
        />
        <g clipPath='url(#clip1_2003_15657)'>
          <path d='M25 14L44 -2V38H25V14Z' fill='white' />
          <path
            d='M35.4761 20.2954L35.4766 20.2947C35.8907 19.7076 35.984 18.857 35.6757 18.1568C35.3742 17.4721 34.678 16.91 33.46 16.91C31.9 16.91 31.1197 18.0837 30.7799 19.1127L30.712 19.3183L30.5124 19.2343L27.9224 18.1443L27.7517 18.0725L27.8103 17.8968C28.5431 15.6981 30.5409 13.8 33.45 13.8C35.8659 13.8 37.5423 14.9031 38.4006 16.3056L38.4008 16.306C38.7684 16.9099 39.0552 17.7665 39.1121 18.6788C39.1691 19.5925 38.9963 20.5767 38.4258 21.4219L38.4255 21.4222C37.8134 22.3251 37.2135 22.9143 36.6966 23.4149C36.6846 23.4266 36.6726 23.4382 36.6606 23.4498C36.1571 23.9373 35.7516 24.3299 35.4657 24.8555C35.3468 25.0744 35.2694 25.2434 35.2182 25.5324C35.1652 25.8313 35.14 26.2611 35.14 27V27.2H34.94H32.05H31.8525L31.85 27.0026C31.8489 26.9144 31.8462 26.8182 31.8432 26.7154C31.8211 25.9377 31.788 24.7756 32.355 23.7531L35.4761 20.2954ZM35.4761 20.2954C35.1067 20.8237 34.6182 21.2744 34.105 21.748M35.4761 20.2954L34.105 21.748M34.105 21.748C34.042 21.806 33.9787 21.8645 33.9152 21.9234M34.105 21.748L33.9152 21.9234M33.9152 21.9234C33.3414 22.456 32.7571 23.0273 32.3551 23.753L33.9152 21.9234ZM33.46 34.2C34.6705 34.2 35.66 33.2105 35.66 32C35.66 30.7895 34.6705 29.8 33.46 29.8C32.2495 29.8 31.26 30.7895 31.26 32C31.26 33.2105 32.2495 34.2 33.46 34.2Z'
            fill='#53B374'
            stroke='#53B374'
            strokeWidth='0.4'
          />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_2003_15657'>
          <rect width='48' height='48' fill='white' />
        </clipPath>
        <clipPath id='clip1_2003_15657'>
          <rect width='48' height='48' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IcHomeQuest;

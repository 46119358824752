// @ts-nocheck
import { makeAutoObservable } from 'mobx';

import {
  TAgencyApartmentItemCamelize,
  TAgencyApartmentItemResponse,
  TAgencyHouseItemCamelize,
  TAgencyHouseItemResponse,
} from '@/types/entities/IAgencyHouse';
import { TApartmentStatus } from '@/types/general/unions';

export class AgencyHouseItem implements TAgencyHouseItemCamelize {
  id: number;
  address: string;
  imageUrl: string;

  constructor(item?: TAgencyHouseItemResponse) {
    if (item) {
      this.id = item.id;
      this.address = item.address;
      this.imageUrl = item.image_url;
    }
    makeAutoObservable(this);
  }
}

export class AgensyApartmentItem implements TAgencyApartmentItemCamelize {
  id: number;
  agencyHouseAddress: string;
  apartmentNumber: number;
  createdAt: string;
  floor: number;
  totalFloors: number;
  imageUrl: string;
  price: number;
  roomsCount: number;
  status: TApartmentStatus;
  totalArea: number;
  updatedAt: string;
  isLoyaltyPrograms?: boolean;

  constructor(item?: TAgencyApartmentItemResponse) {
    if (item) {
      this.id = item.id;
      this.agencyHouseAddress = item.agency_house_address;
      this.apartmentNumber = item.apartment_number;
      this.createdAt = item.created_at;
      this.floor = item.floor;
      this.totalFloors = item.total_floors;
      this.imageUrl = item.image_url;
      this.price = item.price;
      this.roomsCount = item.rooms_count;
      this.status = item.status;
      this.totalArea = item.total_area;
      this.updatedAt = item.updated_at;
      this.isLoyaltyPrograms = item.is_loyalty_programs;
    }
  }
}

import { QuestionCategoryItem } from './list/questionItem';
import { makeAutoObservable, observable, values } from 'mobx';

import QuestionsService from '@/api/rest/QuestionsService';

import { TQuestionCategoryCamelize, TQuestionCategoryResponse } from '@/types/entities/IQuestions';
import { IQuestionsStore } from '@/types/stores/IQuestionsStore';

export class Questions implements IQuestionsStore {
  questionCategories = observable.map<number, TQuestionCategoryCamelize>();
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  get questionsList() {
    return values(this.questionCategories);
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setQuestion(item: TQuestionCategoryCamelize) {
    const newCategory = new QuestionCategoryItem(item);
    this.questionCategories.set(item.id, newCategory);
  }

  async fetchQuestions() {
    this.questionCategories.clear();
    this.setLoading(true);

    const response = await QuestionsService.fetchQuestions();

    if ('data' in response) {
      response.data.question_categories?.forEach((item) => {
        this.setQuestion(item);
      });
    }
    this.setLoading(false);
  }
}

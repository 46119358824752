import { HousingIssuesService } from '@/api';
import { makeAutoObservable } from 'mobx';

import { IHousingIssuesStore } from '@/types/stores/IHousingIssuesStore';

export class HousingIssues implements IHousingIssuesStore {
  count = 0;
  countWithoutDuplicates = 0;
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setCount(count: number, countWithoutDuplicates: number) {
    this.count = count;
    this.countWithoutDuplicates = countWithoutDuplicates;
  }

  async fetchCount() {
    this.setLoading(true);

    const response = await HousingIssuesService.fetchHousingIssueCount();

    if ('data' in response) {
      this.setCount(response.data.count, response.data.count_without_duplicates);
    }

    this.setLoading(false);
  }
}

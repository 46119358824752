import gosuslugi from 'assets/icons/usefulLinks/gosuslugi.png';
import mfc from 'assets/icons/usefulLinks/mfc.svg';
import pensionFund from 'assets/icons/usefulLinks/pensionFund.png';
import socialprotection from 'assets/icons/usefulLinks/socialprotection.png';

export const usefulLinks = [
  {
    title: 'Гос услуги МФЦ',
    href: 'https://www.gosuslugi.ru/',
    img: gosuslugi.src,
  },
  {
    title: 'УЖП',
    href: 'https://mfc.admsakhalin.ru/',
    img: mfc.src,
  },
  {
    title: 'Пенсионный фонд',
    href: 'https://sfr.gov.ru/',
    img: pensionFund.src,
  },
  {
    title: 'Соц. Защита Сахалинской области',
    href: 'https://csp.admsakhalin.ru/',
    img: socialprotection.src,
  },
];

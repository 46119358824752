import React from 'react';

import { Row, SectionWrapper } from '@/components/layouts/Containers';

import { usefulLinks } from './UsefulLinks.data';

import styles from './UsefulLinks.module.scss';

const UsefulLinks = () => {
  return (
    <SectionWrapper className={'mt-6'}>
      <Row>
        <Row.Header>
          <h3 className={'title'}>Полезные ссылки</h3>
        </Row.Header>
        <Row.Content>
          <div className={styles.linksRow}>
            {usefulLinks.map((link, index) => (
              <div key={link.href}>
                <a href={link.href} target='_blank' rel='noreferrer' className={styles.link}>
                  <img className={styles.linkLogo} src={link.img} alt='' />
                </a>
              </div>
            ))}
          </div>
        </Row.Content>
      </Row>
    </SectionWrapper>
  );
};

export default UsefulLinks;

import { FC, ReactNode } from 'react';

import clsx from 'clsx';

import styles from './Wrapper.module.scss';

interface WrapperProps {
  children: ReactNode;
  className?: string;
}

const Wrapper: FC<WrapperProps> = ({ children, className = '' }) => {
  const wrapperStyles: string = clsx({
    [styles.wrapper]: true,
    [className]: className,
  });

  return <div className={wrapperStyles}>{children}</div>;
};

export default Wrapper;

import IcHomeStorage from '@/components/Icons/IcHomeStorage';
import IcUser from '@/components/Icons/IcUser';
import { staticLinks } from '@/config/routingLinks';

export const profileData = [
  {
    title: 'Редактировать профиль',
    link: staticLinks.editProfile,
    icon: <IcUser />,
  },
  {
    title: 'Мои объявления',
    link: staticLinks.personalAdverts,
    icon: <IcHomeStorage />,
  },
];

import { FC } from 'react';

import { TIcon } from './TIcon/TIcon';

const IcPlug: FC<TIcon> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width='94'
      height='94'
      viewBox='0 0 94 94'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.7514 41.4962L14.7515 41.4961L42.1682 14.0793L42.1682 14.0792C44.8366 11.4107 49.1634 11.4107 51.8318 14.0792L51.8318 14.0793L79.2485 41.4961L79.2486 41.4962C80.5301 42.7773 81.25 44.5153 81.25 46.3279V78.3334C81.25 79.9439 79.9442 81.25 78.3333 81.25H54.8333C53.2225 81.25 51.9167 79.9439 51.9167 78.3334V58.75V57.75H50.9167H43.0833H42.0833V58.75V78.3334C42.0833 79.9439 40.7775 81.25 39.1667 81.25H15.6667C14.0559 81.25 12.75 79.9439 12.75 78.3334V46.3279C12.75 44.5153 13.4699 42.7773 14.7514 41.4962ZM47.7071 18.204L47 17.4969L46.2929 18.204L18.8762 45.6208L18.5833 45.9137V46.3279V74.4167V75.4167H19.5833H35.25H36.25V74.4167V54.833C36.25 53.2224 37.5559 51.9163 39.1667 51.9163H54.8333C56.4442 51.9163 57.75 53.2224 57.75 54.833V74.4167V75.4167H58.75H74.4167H75.4167V74.4167V46.3279V45.9137L75.1238 45.6208L47.7071 18.204Z'
        fill='#B4B4B9'
        stroke='#F2F2F2'
        strokeWidth='2'
      />
    </svg>
  );
};

export default IcPlug;

import { FC } from 'react';

import { TIcon } from './TIcon/TIcon';

const IcUser: FC<TIcon> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width='21'
      height='21'
      viewBox='0 0 27 27'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18 7.875C18 10.3603 15.9852 12.375 13.5 12.375C11.0147 12.375 9 10.3603 9 7.875C9 5.38972 11.0147 3.375 13.5 3.375C15.9852 3.375 18 5.38972 18 7.875ZM15.75 7.875C15.75 9.11764 14.7427 10.125 13.5 10.125C12.2573 10.125 11.25 9.11764 11.25 7.875C11.25 6.63236 12.2573 5.625 13.5 5.625C14.7427 5.625 15.75 6.63236 15.75 7.875Z'
        fill='#53B374'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M22.5 19.3124C22.5 18.825 22.3472 18.3458 22.0292 17.9764C19.9662 15.5799 16.9102 14.0625 13.5 14.0625C10.0897 14.0625 7.03389 15.5799 4.97078 17.9764C4.65273 18.3458 4.5 18.825 4.5 19.3124V21.375C4.5 22.6177 5.50736 23.625 6.75 23.625H20.25C21.4927 23.625 22.5 22.6177 22.5 21.375V19.3124ZM20.25 19.3593C18.5977 17.4876 16.1873 16.3125 13.5 16.3125C10.8127 16.3125 8.40231 17.4876 6.75 19.3593V21.375H20.25V19.3593ZM6.75019 19.3075C6.75019 19.3075 6.7502 19.3075 6.75019 19.3076C6.75018 19.3077 6.75017 19.3078 6.75014 19.308C6.75016 19.3078 6.75018 19.3076 6.75019 19.3075Z'
        fill='#53B374'
      />
    </svg>
  );
};

export default IcUser;

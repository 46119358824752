import makeRequest from '@/api/makeRequest';

import { TFeedbackCreate, TRentSearchCreate } from '@/types/entities/IFeedback';

class FeedbackService {
  createFeedback(feedbackData: TFeedbackCreate) {
    return makeRequest<void>({
      url: 'api/feedbacks',
      method: 'post',
      data: feedbackData,
    });
  }
  createRentSearch(feedbackData: TRentSearchCreate, needAuth: boolean) {
    return makeRequest<void>({
      url: 'api/rent_search',
      method: 'post',
      data: feedbackData,
      authToken: needAuth,
    });
  }
  createRentForm(data: FormData, needAuth: boolean) {
    return makeRequest<void>({
      url: 'api/rental_applications',
      method: 'post',
      data: data,
      authToken: needAuth,
    });
  }
}

export default new FeedbackService();

import { FC } from 'react';

import { TIcon } from '../TIcon/TIcon';

const IcTg: FC<TIcon> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M24 12.5C24 19.1274 18.6274 24.5 12 24.5C5.37258 24.5 0 19.1274 0 12.5C0 5.87258 5.37258 0.5 12 0.5C18.6274 0.5 24 5.87258 24 12.5ZM12.43 9.35893C11.2628 9.8444 8.93014 10.8492 5.43189 12.3733C4.86383 12.5992 4.56626 12.8202 4.53917 13.0363C4.49339 13.4015 4.95071 13.5453 5.57347 13.7411C5.65818 13.7678 5.74595 13.7954 5.83594 13.8246C6.44864 14.0238 7.27283 14.2568 7.70129 14.266C8.08994 14.2744 8.52373 14.1142 9.00264 13.7853C12.2712 11.579 13.9584 10.4638 14.0643 10.4398C14.139 10.4228 14.2426 10.4015 14.3128 10.4638C14.3829 10.5262 14.376 10.6443 14.3686 10.676C14.3233 10.8691 12.5281 12.5381 11.5991 13.4018C11.3095 13.671 11.1041 13.862 11.0621 13.9056C10.968 14.0033 10.8721 14.0958 10.78 14.1846C10.2108 14.7333 9.78391 15.1448 10.8036 15.8168C11.2936 16.1397 11.6858 16.4067 12.077 16.6731C12.5042 16.9641 12.9303 17.2543 13.4816 17.6157C13.6221 17.7077 13.7562 17.8034 13.8869 17.8965C14.3841 18.251 14.8307 18.5694 15.3826 18.5186C15.7032 18.4891 16.0345 18.1876 16.2027 17.2884C16.6002 15.1631 17.3816 10.5585 17.5622 8.66097C17.578 8.49473 17.5581 8.28197 17.5422 8.18857C17.5262 8.09518 17.4928 7.96211 17.3714 7.8636C17.2276 7.74694 17.0056 7.72234 16.9064 7.72408C16.455 7.73203 15.7626 7.97282 12.43 9.35893Z'
        fill='#53B374'
      />
    </svg>
  );
};

export default IcTg;

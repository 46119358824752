import { FC } from 'react';

import clsx from 'clsx';

import styles from './Image.module.scss';

interface ImageProps {
  src: string;
  alt: string;
  fluid?: boolean;
}

const Image: FC<ImageProps> = ({ src, alt, fluid }) => {
  const imageStyles: string = clsx({
    [styles.fluid]: fluid,
  });

  return <img src={src} alt={alt} className={imageStyles} />;
};

export default Image;

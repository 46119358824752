import { PaginationStore } from './pagination';
import { AdvertService } from '@/api';
import { action, computed, makeObservable, observable, values } from 'mobx';

import PersonalAdvertsService from '@/api/rest/PersonalAdvertsService';

import {
  TPersonalAdvertItemCamelize,
  TPersonalAdvertItemResponse,
} from '@/types/entities/IPersonalAdvert';
import { TAdvertPhotoCamelize, TAdvertPhotoResponse } from '@/types/general/unions';
import { IPersonalAdvertsStore } from '@/types/stores/IPersonalAdvertsStore';

export class PersonalAdverts extends PaginationStore implements IPersonalAdvertsStore {
  adverts = observable.map<number, TPersonalAdvertItemCamelize>();
  isLoading = false;
  activeCount = 0;
  inactiveCount = 0;
  rejectedCount = 0;

  constructor() {
    super();
    makeObservable(this, {
      adverts: observable,
      isLoading: observable,
      activeCount: observable,
      inactiveCount: observable,
      rejectedCount: observable,
      list: computed,
      setLoading: action,
      setAdvert: action,
      deleteAdvert: action,
    });
  }

  get list() {
    return values(this.adverts);
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setAdvert(item: TPersonalAdvertItemCamelize) {
    this.adverts.set(item.id, item);
  }

  setCounters(activeCount: number, inactiveCount: number, rejectedCount: number) {
    this.activeCount = activeCount;
    this.inactiveCount = inactiveCount;
    this.rejectedCount = rejectedCount;
  }

  deleteAdvert(id: number) {
    this.adverts.delete(id);
  }

  serialize(item: TPersonalAdvertItemResponse) {
    return {
      id: item.id,
      propertyType: item.property_type,
      dealType: item.deal_type,
      rentType: item.rent_type,
      totalArea: item.total_area,
      totalFloors: item.total_floors,
      address: item.address,
      price: item.price,
      deposit: item.deposit,
      commission: item.commission,
      roomsCount: item.rooms_count,
      measuringPlotArea: item.measuring_plot_area,
      appointment: item.appointment,
      plotArea: item.plot_area,
      floor: item.floor,
      periodOfPlacement: item.period_of_placement,
      photos: this.serializePhotos(item.photos),
      isApproved: item.is_approved,
      isActive: item.is_active,
      createdAt: item.created_at,
    };
  }

  serializePhotos(photos?: TAdvertPhotoResponse[]): TAdvertPhotoCamelize[] {
    return (
      photos?.map((photo) => {
        return {
          id: photo.id,
          description: photo.description,
          url: photo.url,
          isMain: photo.is_main,
          thumbUrl: photo.thumb_url,
        };
      }) || ([] as TAdvertPhotoCamelize[])
    );
  }

  async fetchPersonalAdverts(query: string) {
    this.adverts.clear();
    this.setLoading(true);

    const response = await PersonalAdvertsService.fetchPersonalAdverts(query);

    if ('data' in response) {
      response.data.adverts.forEach((item) => {
        this.setAdvert(this.serialize(item));
      });
      this.setCounters(
        response.data.active_count,
        response.data.inactive_count,
        response.data.rejected_count,
      );
    }
    this.setLoading(false);
  }

  async deletePersonalAdvert(id: number) {
    const response = await AdvertService.deleteAdvert(id);

    if (response.status === 200) {
      this.deleteAdvert(id);
      this.updateCounters();
    }
    return response;
  }

  async updateCounters() {
    this.setLoading(true);

    const response = await PersonalAdvertsService.fetchPersonalAdverts('?is_active=1&is_approved=1');

    if ('data' in response) {
      this.setCounters(
        response.data.active_count,
        response.data.inactive_count,
        response.data.rejected_count,
      );
    }
    this.setLoading(false);
  }
}

import { FC } from 'react';

import { TIcon } from '../TIcon/TIcon';

const IcContacts: FC<TIcon> = ({ className, onClick }) => {
  return (
    <svg className={className} onClick={onClick} width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M37.2222 37.1111H10.7779V9.88893H37.2222M37.2222 6.00004H10.7779C9.77596 6.00004 7.97173 5.99999 7.00026 6C7 7 7.00012 8.85753 7.00012 9.88893V37.1111C7.00012 38.1425 7.00006 40 7.0002 41C7.97169 41 9.77596 41 10.7779 41H37.2222C38.2242 41 40.0286 41 41 41C41 40 41 38.1425 41 37.1111V9.88893C41 8.85753 41 6.99997 41 6C40.0286 6 38.2242 6.00004 37.2222 6.00004ZM32.5 31.7639C32.5 28.8472 26.8334 27.3889 24.0001 27.3889C21.1667 27.3889 15.5001 28.8472 15.5001 31.7639V33.2222H32.5M24.0001 23.9861C25.1272 23.9861 26.2082 23.5252 27.0053 22.7047C27.8023 21.8843 28.2501 20.7715 28.2501 19.6111C28.2501 18.4508 27.8023 17.338 27.0053 16.5175C26.2082 15.6971 25.1272 15.2361 24.0001 15.2361C22.8729 15.2361 21.7919 15.6971 20.9949 16.5175C20.1978 17.338 19.7501 18.4508 19.7501 19.6111C19.7501 20.7715 20.1978 21.8843 20.9949 22.7047C21.7919 23.5252 22.8729 23.9861 24.0001 23.9861Z" fill="#53B374"/>
</svg>




  );
};

export default IcContacts;
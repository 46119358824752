import makeRequest from '@/api/makeRequest';

import { GetQuestionsResponse } from '@/types/api/IQuestionsResponse';

class QuestionsService {
  fetchQuestions() {
    return makeRequest<GetQuestionsResponse>({
      url: 'api/questions',
    });
  }
}

export default new QuestionsService();

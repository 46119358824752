import { AgencyHouseItem, AgensyApartmentItem } from './list/agencyHouses';
import { PaginationStore } from './pagination';
import { action, computed, makeObservable, observable, values } from 'mobx';

import AgencyHousesService from '@/api/rest/AgencyHousesService';

import { APIError } from '@/types/api/IAPI';
import {
  IBooking,
  TAgencyApartmentItemCamelize,
  TAgencyApartmentItemResponse,
  TAgencyHouseItemCamelize,
  TAgencyHouseItemResponse,
} from '@/types/entities/IAgencyHouse';
import { IAgencyHouses } from '@/types/stores/IAgencyHouses';

export class AgencyHouses extends PaginationStore implements IAgencyHouses {
  agencyHousesItems = observable.map<number, TAgencyHouseItemCamelize>();
  agencyApartmentsItems = observable.map<number, TAgencyApartmentItemCamelize>();
  houseAddress = '';
  isLoading = false;

  constructor() {
    super();
    makeObservable(this, {
      agencyHousesItems: observable,
      agencyApartmentsItems: observable,
      houseAddress: observable,
      isLoading: observable,
      agencyHousesList: computed,
      agencyApartmentsList: computed,
      setLoading: action,
      setHouseAddress: action,
      setAgencyHouse: action,
      setAgencyApartment: action,
    });
  }

  get agencyHousesList() {
    return values(this.agencyHousesItems);
  }

  get agencyApartmentsList() {
    return values(this.agencyApartmentsItems);
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setHouseAddress(address: string) {
    this.houseAddress = address;
  }

  setAgencyHouse(item: TAgencyHouseItemResponse) {
    const newAgencyHouse = new AgencyHouseItem(item);
    this.agencyHousesItems.set(item.id, newAgencyHouse);
  }

  setAgencyApartment(item: TAgencyApartmentItemResponse) {
    const newAgencyApartment = new AgensyApartmentItem(item);
    this.agencyApartmentsItems.set(item.id, newAgencyApartment);
  }

  async fetchAgencyHouses() {
    this.agencyHousesItems.clear();
    this.setLoading(true);

    const response = await AgencyHousesService.fetchAgencyHouses();

    if ('data' in response) {
      response.data?.agency_houses?.forEach((item) => {
        this.setAgencyHouse(item);
      });
    }
    this.setLoading(false);
  }

  async fetchAgencyApartments(id: string, query: string) {
    this.agencyApartmentsItems.clear();
    this.setLoading(true);

    const response = await AgencyHousesService.fetchAgencyApartments(id, query);

    if ('data' in response) {
      this.setHouseAddress(response.data.meta.house_address);
      response.data?.agency_apartments?.forEach((item) => {
        this.setAgencyApartment(item);
      });

      const { meta } = response.data;
      this.setLastPage(meta?.last_page ?? 1);
      this.setCurrentPage(meta?.current_page ?? 1);
    } else {
      this.setLastPage(1);
      this.setCurrentPage(1);
    }

    this.setLoading(false);
  }

  async bookApartment(info: IBooking, needAuth: boolean) {
    const response = await AgencyHousesService.bookApartment(info, needAuth);
    return response;
  }

  serializeBookingErrors(errors: APIError['errors']) {
    return {
      borrowerCategory: errors.borrower_category,
      paymentMethod: errors.payment_method,
      firstName: errors.first_name,
      lastName: errors.last_name,
      email: errors.email,
      phone: errors.phone,
    };
  }
}

import { useContext } from 'react';

import { Context } from '@/components/Logic/StoreProvider/StoreProvider';
import { AppContextInterface } from '@/components/Logic/StoreProvider/types';

import { IRootStore } from '@/types/stores/IRootStore';

type TUseStore = () => IRootStore;

export const useStore: TUseStore = () => {
  const context = useContext(Context) as AppContextInterface;
  return context.store;
};

import { FC } from 'react';

import { TIcon } from '../TIcon/TIcon';

const IcVk: FC<TIcon> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width='24'
      height='25'
      viewBox='0 0 24 25'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M1.68707 2.18707C0 3.87413 0 6.5894 0 12.02V12.98C0 18.4106 0 21.1259 1.68707 22.813C3.37413 24.5 6.0894 24.5 11.52 24.5H12.48C17.9106 24.5 20.6259 24.5 22.313 22.813C24 21.1259 24 18.4106 24 12.98V12.02C24 6.5894 24 3.87413 22.313 2.18707C20.6259 0.5 17.9106 0.5 12.48 0.5H11.52C6.0894 0.5 3.37413 0.5 1.68707 2.18707ZM4.05006 7.80005C4.18006 14.04 7.30005 17.7901 12.7701 17.7901H13.0801V14.22C15.0901 14.42 16.61 15.8901 17.22 17.7901H20.06C19.28 14.9501 17.2299 13.3801 15.95 12.7801C17.2299 12.0401 19.0299 10.24 19.4599 7.80005H16.8799C16.3199 9.78005 14.6601 11.58 13.0801 11.75V7.80005H10.5V14.72C8.9 14.3201 6.88005 12.38 6.79005 7.80005H4.05006Z'
        fill='#53B374'
      />
    </svg>
  );
};

export default IcVk;

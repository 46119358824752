import { FC } from 'react';

import { TIcon } from './TIcon/TIcon';

const IcFile: FC<TIcon> = ({ className, onClick }) => {
  return (
    <svg className={className} onClick={onClick} width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_7196_44595)">
        <path d="M4.93286 15.2539V8.95093H-0.090332V15.2539C-0.090332 17.4685 1.10653 19.5217 3.05596 20.6334L8.70479 23.8612V18.123L5.56756 16.3298C5.16861 16.1057 4.93286 15.7022 4.93286 15.2539Z" fill="#BBBBBB"/>
        <path d="M8.69583 21.3777L14.3537 18.15C16.2941 17.0382 17.5 14.9851 17.5 12.7705V0.25415H8.70489V5.22124H12.4678V12.7615C12.4678 13.2009 12.232 13.6133 11.8421 13.8374L8.70489 15.6306V21.3777H8.69583Z" fill="#E8E8E8" fill-opacity="0.97"/>
        <path d="M8.69572 0.25415L-0.090332 8.95104H8.69572V0.25415Z" fill="#666666"/>
      </g>
      <defs>
        <clipPath id="clip0_7196_44595">
          <rect width="18" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>

  );
};

export default IcFile;

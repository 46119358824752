import { FC } from 'react';

import { TIcon } from '../TIcon/TIcon';

const IcHomeRubble: FC<TIcon> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_2003_15744)'>
        <path
          d='M10 38H16H28H34V20L22 11L10 20V38ZM6 42V18L22 6L38 18V42H24H20H6Z'
          fill='#53B374'
        />
        <path d='M25 14L44 -2V38H25V14Z' fill='white' />
        <path
          d='M38.0364 20.5866C38.0364 19.7344 37.7656 19.044 37.224 18.5156C36.6824 17.9872 35.9699 17.723 35.0867 17.723H31.087V23.4503H35.0867C35.9699 23.4503 36.6824 23.1861 37.224 22.6577C37.7656 22.1293 38.0364 21.4389 38.0364 20.5866ZM40.9987 20.5866C40.9987 22.2315 40.4716 23.5739 39.4175 24.6136C38.3635 25.6534 37.0032 26.1733 35.3366 26.1733H31.087V27.6818H37.399C37.5156 27.6818 37.6114 27.7202 37.6864 27.7969C37.7614 27.8736 37.7989 27.9716 37.7989 28.0909V29.7273C37.7989 29.8466 37.7614 29.9446 37.6864 30.0213C37.6114 30.098 37.5156 30.1364 37.399 30.1364H31.087V32.5909C31.087 32.7102 31.0474 32.8082 30.9683 32.8849C30.8891 32.9616 30.7954 33 30.687 33H28.5997C28.483 33 28.3872 32.9616 28.3122 32.8849C28.2372 32.8082 28.1997 32.7102 28.1997 32.5909V30.1364H25.4C25.2833 30.1364 25.1875 30.098 25.1125 30.0213C25.0375 29.9446 25 29.8466 25 29.7273V28.0909C25 27.9716 25.0375 27.8736 25.1125 27.7969C25.1875 27.7202 25.2833 27.6818 25.4 27.6818H28.1997V26.1733H25.4C25.2833 26.1733 25.1875 26.1349 25.1125 26.0582C25.0375 25.9815 25 25.8835 25 25.7642V23.8594C25 23.7486 25.0375 23.6527 25.1125 23.5717C25.1875 23.4908 25.2833 23.4503 25.4 23.4503H28.1997V15.4091C28.1997 15.2898 28.2372 15.1918 28.3122 15.1151C28.3872 15.0384 28.483 15 28.5997 15H35.3366C37.0032 15 38.3635 15.5199 39.4175 16.5597C40.4716 17.5994 40.9987 18.9418 40.9987 20.5866Z'
          fill='#53B374'
        />
      </g>
      <defs>
        <clipPath id='clip0_2003_15744'>
          <rect width='48' height='48' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IcHomeRubble;

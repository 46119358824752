import AdvertService from './rest/AdvertService';
import AuthService from './rest/AuthService';
import FeedbackService from './rest/FeedbackService';
import HousingIssuesService from './rest/HousingIssuesService';
import NewsService from './rest/NewsService';
import PhotoReportService from './rest/PhotoReportService';
import TradeInAdvertService from './rest/TradeInAdvertService';

export {
  AuthService,
  AdvertService,
  NewsService,
  PhotoReportService,
  FeedbackService,
  HousingIssuesService,
  TradeInAdvertService,
};

import React, { FC } from 'react';

import clsx from 'clsx';

import { ButtonProps } from './types';

import styles from './Button.module.scss';

const Button: FC<ButtonProps> = ({
  theme = 'outlined',
  type = 'button',
  size = 'default',
  className = '',
  children,
  ...props
}) => {
  const buttonStyle: string = clsx({
    [styles.button]: true,
    [className]: className,
    [styles.filled]: theme === 'filled',
    [styles.outlined]: theme === 'outlined',
    [styles.grey]: theme === 'grey',
    [styles.dark]: theme === 'dark',
    [styles.small]: size === 'small',
  });

  return (
    <button type={type} className={buttonStyle} {...props}>
      {children}
    </button>
  );
};

export default Button;

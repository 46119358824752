import React, { FC } from 'react';

import clsx from 'clsx';

import { AccordionSummaryProps } from './types';

import styles from './Accordion.module.scss';

const AccordionSummary: FC<AccordionSummaryProps> = ({ className = '', children, onClick }) => {
  const accordionSummaryStyle = clsx({
    [styles.accordionHeader]: true,
    [className]: true,
  });

  return (
    <div className={accordionSummaryStyle} onClick={onClick}>
      {children}
    </div>
  );
};

export default AccordionSummary;

import { action, makeAutoObservable, makeObservable, observable } from 'mobx';

import { IPaginationStore } from '@/types/stores/IPaginationStore';

export class PaginationStore implements IPaginationStore {
  currentPage = 1;
  lastPage = 1;
  amount = 0;

  constructor() {
    makeObservable(this, {
      currentPage: observable,
      lastPage: observable,
      amount: observable,
      setCurrentPage: action,
      setLastPage: action,
    });
  }

  setCurrentPage(page: number) {
    this.currentPage = page;
  }

  setLastPage(page: number) {
    this.lastPage = page;
  }

  setAmount(amount: number) {
    this.amount = amount;
  }
}

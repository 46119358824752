import Button from './Button/Button';
import CardsRow from './CardsRow/CardsRow';
import CategoryCard from './CategoryCard/CategoryCard';
import CategoryCardPost from './CategoryCardPost/CategoryCardPost';
import Disclaimer from './Disclaimer/Disclaimer';
import DownloadBtn from './DownloadBtn/DownloadBtn';
import FlatCard from './FlatCard/FlatCard';
import FlatCardExternal from './FlatCard/FlatCardExternal';
import FlatCardSkeleton from './FlatCard/Skeleton/FlatCardSkeleton';
import Image from './Image/Image';
import ImageWithBlur from './ImageWithBlur/ImageWithBlur';
import ImageWrapper from './ImageWrapper/ImageWrapper';
import Loader from './Loader/Loader';
import MapPlug from './MapPlug/MapPlug';
import NewsCard from './NewsCard/NewsCard';
import NewsCardSkeleton from './NewsCard/Skeleton/NewsCardSkeleton';
import NumberCard from './NumberCard/NumberCards';
import Phone from './Phone/Phone';
import SeeAllLink from './SeeAllLink/SeeAllLink';
import Sort from './Sort/Sort';
import UsefulLinks from './UsefulLinks/UsefulLinks';
import dynamic from 'next/dynamic';

export {
  Button,
  CardsRow,
  CategoryCard,
  CategoryCardPost,
  Disclaimer,
  DownloadBtn,
  FlatCard,
  FlatCardExternal,
  FlatCardSkeleton,
  Image,
  Loader,
  NewsCard,
  NewsCardSkeleton,
  NumberCard,
  Phone,
  SeeAllLink,
  Sort,
  MapPlug,
  ImageWithBlur,
  UsefulLinks,
  ImageWrapper,
};

export const SwiperCards = dynamic(() => import('./SwiperCards/SwiperCards'), {
  ssr: false,
});
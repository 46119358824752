import { FC } from 'react';

import clsx from 'clsx';

import styles from './Phone.module.scss';

interface PhoneProps {
  phone: string;
  className?: string;
}

const Phone: FC<PhoneProps> = ({ phone, className = '' }) => {
  const phoneStyles: string = clsx({
    [styles.phone]: true,
    [className]: className,
  });

  return (
    <a className={phoneStyles} href={`tel: ${phone}`}>
      {phone}
    </a>
  );
};

export default Phone;

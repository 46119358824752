import { useEffect, useState } from 'react';

import Burger from './Burger/Burger';
import Menu from './Menu/Menu';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';

import { CheckElementAccess, Popover } from '@/components/Logic';
import { useStore } from '@/hooks/useStore';

import { profileData } from './Header.data';
import { staticLinks } from '@/config/routingLinks';

import { THeaderPopovers } from '@/types/general/unions';

import styles from './Header.module.scss';
import IcLogo from '@/components/Icons/general/Iclogo';
import IcHintGray from '@/components/Icons/IcHintGray';
import IcProfile from '@/components/Icons/IcProfile';
import IcLogout from '@/components/Icons/IcLogout';
import { useRouter } from 'next/router';
import Link from 'next/link';
import IcHintGreen from '@/components/Icons/IcHintGreen';

const Header = observer(() => {
  const store = useStore();

  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [prevScrollPos, setPrevScrollPos] = useState<number>(window.scrollY);
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [currentPopover, setCurrentPopover] = useState<THeaderPopovers | null>(null);
  const [isBurgerActive, setIsBurgerActive] = useState(false);
  const [isHintActive, setIsHintActive] = useState(false);
  const [isProfileActive, setIsProfileActive] = useState(false);

  const [refHint, setRefHint] = useState<HTMLDivElement | null>(null);
  const [refProfile, setRefProfile] = useState<HTMLDivElement | null>(null);

  const router = useRouter();

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos: number = window.scrollY;
      const isScrollingDown: boolean = currentScrollPos > prevScrollPos;

      if (currentScrollPos >= 200) {
        setIsVisible(!isScrollingDown);
        setPrevScrollPos(currentScrollPos);
      }
    };

    window.addEventListener('scroll', handleScroll);
    if(!isVisible){
      setPopoverOpen(false);
      setIsProfileActive(false);
    }
    return () => window.removeEventListener('scroll', handleScroll);


  }, [prevScrollPos, isVisible]);

  useEffect(() => {
    setIsVisible(true);
  }, [router.pathname]);

  const links = [staticLinks.mapAdverts, staticLinks.mapExternalAdverts];

  const headerStyles: string = clsx({
    [styles.header]: true,
    [styles.visible]: isVisible,
    [styles.headerMap]: links.includes(location.pathname),
    [styles.isActive]: isPopoverOpen,
  });
  const buttonStyles = (popover: THeaderPopovers) =>
    clsx({
      [styles.dropdownBtn]: true,
      [styles.active]: currentPopover === popover,
    });
  const homeApplicationStyles = (popover: THeaderPopovers) =>
    clsx({
      [styles.dropdownBtn]: true,
      [styles.activeHomeApplication]: currentPopover === popover,
      [styles.homeApplicationBtn]: true,
    });

  const hintButtonStyles = clsx(styles.hintBtn, {
    [styles.active]: isHintActive,
  });

  const openPopover = (popover: THeaderPopovers) => {
    if (currentPopover === popover) setCurrentPopover(null);
    else setCurrentPopover(popover);
    setPopoverOpen(popover !== currentPopover || (popover === currentPopover && !isPopoverOpen));
  };

  const onPopoverClose = () => {
    setPopoverOpen(false);
    setCurrentPopover(null);
  };

  const onProfileClick = () => {
    setIsProfileActive(true);
    onPopoverClose();
  };

  const onLogout = () => {
    store.auth.logout();
    setIsProfileActive(false);
    router.push(staticLinks.authorization);
  };

  return (
    <header className={headerStyles}>
      <div className={styles.wrapper}>
        <div className={styles.group}>
          <Burger
            isActive={isBurgerActive}
            setIsBurgerActive={setIsBurgerActive}
            currentTab={currentPopover}
          />

          <Link
            href={staticLinks.main}
            onClick={() => {
              setIsBurgerActive(false);
              onPopoverClose();
            }}
            className={styles.logo}
          >
            <IcLogo />
          </Link>
        </div>

        <nav className={styles.navList}>
          <button onClick={() => openPopover('rent')} className={buttonStyles('rent')}>
            <span>Аренда</span>
          </button>

          <button onClick={() => openPopover('sale')} className={buttonStyles('sale')}>
            <span>Продажа</span>
          </button>

          <Link href={staticLinks.horizont} className={styles.dropdownBtn}>
            <span>ЖК «Горизонт»</span>
          </Link>

          <Link href={staticLinks.mortgage} onClick={onPopoverClose} className={styles.dropdownBtn}>
            <span>Ипотека</span>
          </Link>

          <button
            onClick={() => openPopover('housingIssues')}
            className={homeApplicationStyles('housingIssues')}
          >
            <span>Сахалинская ипотека</span>
            <span>Улучшение жилищных условий</span>
          </button>

          <button onClick={() => openPopover('additional')} className={buttonStyles('additional')}>
            <span>Ещё</span>
          </button>
        </nav>

        <Menu isMenuActive={isPopoverOpen} closeMenu={onPopoverClose} currentTab={currentPopover} />

        <div className={styles.buttons}>
          {/* <div className={styles.hintBtn} ref={setRefHint}>
            {isHintActive ? (
              <IcHintGreen onClick={() => setIsHintActive(!isHintActive)} />
            ) : (
              <IcHintGray onClick={() => setIsHintActive(!isHintActive)} />
            )}
          </div>
            <Popover
              isOpen={isHintActive}
              offset={[89, 15]}
              onClose={() => setIsHintActive(false)}
              refEl={refHint}
              className={styles.hint}
            >
              <div></div>
              Бесплатно до 31.12.2023
            </Popover> */}
          <CheckElementAccess checkAuth={true}>
            <Link href={staticLinks.createAdvert} className={styles.advtBtn}>
              Разместить объявление
            </Link>
          </CheckElementAccess>
          <CheckElementAccess checkAuth={false}>
            <Link
              href={staticLinks.authorization}
              onClick={onPopoverClose}
              className={styles.advtBtn}
            >
              Разместить объявление
            </Link>
          </CheckElementAccess>

          <CheckElementAccess checkAuth={true}>
            <div onClick={onProfileClick} className={styles.profileBtn} ref={setRefProfile}>
              <IcProfile />
            </div>
          </CheckElementAccess>
           <Popover
              isOpen={isProfileActive}
              onClose={() => setIsProfileActive(false)}
              refEl={refProfile}
              className={styles.profile}>
            <ul>
              {profileData.map((item) => (
                <li key={item.title}>
                  <Link href={item.link} onClick={() => setIsProfileActive(false)}>
                    {item.icon}
                    <span>{item.title}</span>
                  </Link>
                </li>
              ))}
              <li>
                <button onClick={onLogout}>
                  <IcLogout />
                  <span>Выйти</span>
                </button>
              </li>
            </ul>
          </Popover>
        </div>
      </div>
    </header>
  );
});

export default Header;

import { FC, ReactNode } from 'react';

import styles from './CardsRow.module.scss';

interface CardsRowProps {
  children: ReactNode;
}

const CardsRow: FC<CardsRowProps> = ({ children }) => {
  return <div className={styles.row}>{children}</div>;
};

export default CardsRow;

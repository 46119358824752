import { FC, ReactNode } from 'react';
import { Helmet } from 'react-helmet';

import Container from '../Container/Container';
import clsx from 'clsx';

import styles from './Main.module.scss';
import NextWrapper from '@/components/layouts/Containers/NextWrapper/NextWrapper';

type TMeta = {
  title: string;
  description?: string;
  keywords?: string;
};

interface MainProps {
  meta: TMeta;
  children: ReactNode;
  className?: string;
}

const Main: FC<MainProps> = ({ children, className = '', meta }) => {
  const mainStyles: string = clsx({
    [styles.main]: true,
    [className]: className,
  });

  return (
    <>
      <NextWrapper meta={meta}></NextWrapper>
      <main className={mainStyles}>
        <Container>{children}</Container>
      </main>
    </>
  );
};

export default Main;

import styles from './FlatCardSkeleton.module.scss';

const FlatCardSkeleton = () => {
  return (
    <li className={styles.item}>
      <div className={styles.image}></div>
      <div className={styles.content}>
        <div className={styles.date}></div>
        <div className={styles.status}></div>
        <div className={styles.info}></div>
        <div className={styles.price}></div>
        <div className={styles.address}></div>
      </div>
    </li>
  );
};

export default FlatCardSkeleton;

// @ts-nocheck
import { PhotoReportService } from '@/api';
import { makeAutoObservable, toJS } from 'mobx';

import { TPhotoReportCamelize, TPhotoReportView } from '@/types/entities/IPhotoReports';
import { TNewsPhotoCamelize, TNewsPhotoResponse } from '@/types/general/unions';
import { IPhotoReportStore } from '@/types/stores/IPhotoReportStore';

export class PhotoReport implements IPhotoReportStore {
  photoReport: TPhotoReportCamelize;
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  get profile() {
    return toJS(this.photoReport);
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setPhotoReport(photoReport: TPhotoReportCamelize) {
    this.photoReport = photoReport;
  }

  serializePhotoReport(item: TPhotoReportView) {
    return {
      id: item.id,
      name: item.name,
      text: item.text,
      type: item.type,
      images: this.serializePhotos(item.images),
      updatedAt: item.updated_at,
      createdAt: item.created_at,
    };
  }

  serializePhotos(photos?: TNewsPhotoResponse[]): TNewsPhotoCamelize[] {
    return (
      photos?.map((photo) => {
        return {
          id: photo.id,
          url: photo.url,
          thumbUrl: photo.thumb_url,
        };
      }) || ([] as TNewsPhotoCamelize[])
    );
  }

  async fetchPhotoReport(id: string) {
    this.setLoading(true);

    const response = await PhotoReportService.fetchPhotoReport(id);

    if ('data' in response) {
      this.setPhotoReport(this.serializePhotoReport(response.data.news));
    }

    this.setLoading(false);
  }
}

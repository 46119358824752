import dynamic from 'next/dynamic';

import Accordion from './Accordion/Accordion';
import AccordionContent from './Accordion/AccordionContent';
import AccordionSummary from './Accordion/AccordionSummary';
import CheckElementAccess from './CheckElementAccess/CheckElementAccess';
import MainAccordion from './MainAccordion/MainAccordion';
import Pagination from './Pagination/Pagination';
import Popover from './Poppers/Popover/Popover';
import Popup from './Poppers/Popup/Popup';
import ScrollToTop from './ScrollToTop/ScrollToTop';
import StoreProvider from './StoreProvider/StoreProvider';
import ToastMessage from './ToastMessage/ToastMessage';

export {
  CheckElementAccess,
  MainAccordion,
  Pagination,
  ScrollToTop,
  StoreProvider,
  ToastMessage,
  Popover,
  Popup,
  Accordion,
  AccordionContent,
  AccordionSummary,
};


export const MapComponent = dynamic(() => import('./MapComponent/MapComponent'), {
  ssr: false,
});
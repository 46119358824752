import makeRequest from '@/api/makeRequest';

import {
  GetTradeInAdvertResponse,
  GetTradeInAdvertsResponse,
} from '@/types/api/ITradeInAdvertResponse';

class TradeInAdvertService {
  fetchTradeInAdvert(id: string) {
    return makeRequest<GetTradeInAdvertResponse>({
      url: `api/trade_in/${id}`,
    });
  }

  fetchTradeInAdverts(query: string) {
    return makeRequest<GetTradeInAdvertsResponse>({
      url: `api/trade_in${query}`,
    });
  }

  createTradeInAdvert(advert: FormData) {
    return makeRequest<GetTradeInAdvertResponse>({
      url: 'api/trade_in',
      authToken: true,
      method: 'POST',
      data: advert,
    });
  }

  updateFiles(advertId: string, files: FormData) {
    return makeRequest<GetTradeInAdvertResponse>({
      url: `api/trade_in/${advertId}/files_update`,
      method: 'POST',
      data: files,
      authToken: true,
    });
  }

  updatePhotoDescription(advertId: string, photoId: number, description: string) {
    return makeRequest<GetTradeInAdvertResponse>({
      url: `api/trade_in/${advertId}/photos/${photoId}/description_update`,
      method: 'PUT',
      data: { description },
      authToken: true,
    });
  }

  updateMainPhoto(advertId: string, photoId: number) {
    return makeRequest<GetTradeInAdvertResponse>({
      url: `api/trade_in/${advertId}/photos/main_update`,
      method: 'PUT',
      data: { main_photo_id: photoId },
      authToken: true,
    });
  }

  deletePhoto(advertId: string, photoId: number) {
    return makeRequest<void>({
      url: `api/trade_in/${advertId}/photos/${photoId}`,
      method: 'DELETE',
      authToken: true,
    });
  }
}

export default new TradeInAdvertService();

import { FC } from 'react';
import { Helmet } from 'react-helmet';

import clsx from 'clsx';

import { IPageWrapper } from './types';

import styles from './PageWrapper.module.scss';
import NextWrapper from '@/components/layouts/Containers/NextWrapper/NextWrapper';

const PageWrapper: FC<IPageWrapper> = ({ meta, mainStyles, children }) => {
  const main: string = clsx({
    [mainStyles]: true,
    [styles.main]: true,
  });

  return (
    <>
     <NextWrapper meta={meta}></NextWrapper>
      <main className={main}>{children}</main>
    </>
  );
};

export default PageWrapper;

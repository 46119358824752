import { FC } from 'react';

import clsx from 'clsx';
import PropTypes from 'prop-types';

import { RowContentProps, RowHeader, RowProps } from './types';

import styles from './Row.module.scss';

const Row: FC<RowProps> & { Content: FC<RowContentProps> } & {
  Header: FC<RowHeader>;
} = ({ children, className = '' }) => {
  const rowStyles: string = clsx({
    [styles.wrapper]: true,
    [className]: className,
  });

  return <div className={rowStyles}>{children}</div>;
};

Row.Content = ({ children }) => <div className={styles.content}>{children}</div>;

Row.Header = ({ children }) => <div className={styles.header}>{children}</div>;

Row.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

Row.Content.propTypes = {
  children: PropTypes.node.isRequired,
};

Row.Header.propTypes = {
  children: PropTypes.node.isRequired,
};

Row.Content.displayName = 'RowContent';

Row.Header.displayName = 'RowHeader';

export default Row;

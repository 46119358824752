// @ts-nocheck
import { TAdvertPhotoCamelize, TAdvertPhotoResponse } from '../types/general/unions';
import { TradeInAdvertService } from '@/api';
import { makeAutoObservable, toJS } from 'mobx';

import { APIError } from '@/types/api/IAPI';
import {
  TTradeInAdvertViewCamelize,
  TTradeInAdvertViewResponse,
} from '@/types/entities/ITradeInAdvert';
import { ITradeInAdvertStore } from '@/types/stores/ITradeInAdvertStore';

export class TradeInAdvert implements ITradeInAdvertStore {
  tradeInAdvert: TTradeInAdvertViewCamelize;
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  get profile() {
    return toJS(this.tradeInAdvert);
  }

  setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  setTradeInAdvert(tradeInAdvert: TTradeInAdvertViewCamelize) {
    this.tradeInAdvert = tradeInAdvert;
  }

  deleteTradeInAdvertPhoto(id: number) {
    this.tradeInAdvert.photos = this.tradeInAdvert.photos?.filter((photo) => photo.id === id);
  }

  serialize(tradeInAdvert: TTradeInAdvertViewResponse) {
    return {
      id: tradeInAdvert.id,
      wishfulBuilder: tradeInAdvert.wishful_builder,
      wishfulResidentialComplex: tradeInAdvert.wishful_residential_complex,
      wishfulRoomsCount: tradeInAdvert.wishful_rooms_count,
      wishfulFloor: tradeInAdvert.wishful_floor,
      wishfulTotalArea: tradeInAdvert.wishful_total_area,
      wishfulPrice: tradeInAdvert.wishful_price,
      dealType: tradeInAdvert.deal_type,
      totalArea: tradeInAdvert.total_area,
      roomsCount: tradeInAdvert.rooms_count,
      layoutType: tradeInAdvert.layout_type,
      floor: tradeInAdvert.floor,
      totalFloors: tradeInAdvert.total_floors,
      repair: tradeInAdvert.repair,
      balcony: tradeInAdvert.balcony,
      isHavePhone: tradeInAdvert.is_have_phone,
      isHaveTv: tradeInAdvert.is_have_tv,
      isHaveInternet: tradeInAdvert.is_have_internet,
      passengerLift: tradeInAdvert.passenger_lift,
      serviceLift: tradeInAdvert.service_lift,
      isPossibleMortgage: tradeInAdvert.is_possible_mortgage,
      price: tradeInAdvert.price,
      description: tradeInAdvert.description,
      linkToVideo: tradeInAdvert.link_to_video,
      photos: this.serializePhotos(tradeInAdvert.photos),
      FIO: tradeInAdvert.FIO,
      phone: tradeInAdvert.phone,
      email: tradeInAdvert.email,
      createdAt: tradeInAdvert.created_at,
      updatedAt: tradeInAdvert.updated_at,
    };
  }

  serializePhotos(photos?: TAdvertPhotoResponse[]): TAdvertPhotoCamelize[] {
    return (
      photos?.map((photo) => {
        return {
          id: photo.id,
          description: photo.description,
          url: photo.url,
          isMain: photo.is_main,
          thumbUrl: photo.thumb_url,
        };
      }) || ([] as TAdvertPhotoCamelize[])
    );
  }

  serializeErrors(errors: APIError['errors']) {
    for (const [key, value] of Object.entries(errors)) {
      if (key.includes('photos') && Array.isArray(value)) {
        errors.photos ? errors.images.concat(value as Array<string>) : (errors.images = value);
        delete errors[key];
      }
    }

    return {
      dealType: errors.deal_type,
      wishfulBuilder: errors.wishful_builder,
      wishfulResidentialComplex: errors.wishful_residential_complex,
      wishfulRoomsCount: errors.wishful_rooms_count,
      wishfulFloor: errors.wishful_floor,
      wishfulTotalArea: errors.wishful_total_area,
      wishfulPrice: errors.wishful_price,
      totalArea: errors.total_area,
      roomsCount: errors.rooms_count,
      layoutType: errors.layout_type,
      floor: errors.floor,
      totalFloors: errors.total_floors,
      repair: errors.repair,
      balcony: errors.balcony,
      isHavePhone: errors.is_have_phone,
      isHaveTv: errors.is_have_tv,
      isHaveInternet: errors.is_have_internet,
      passengerLift: errors.passenger_lift,
      serviceLift: errors.service_lift,
      isPossibleMortgage: errors.is_possible_mortgage,
      price: errors.price,
      description: errors.description,
      linkToVideo: errors.video,
      photos: errors.photos,
      FIO: errors.FIO,
      phone: errors.phone,
      email: errors.email,
    };
  }

  async fetchTradeInAdvert(id: string) {
    this.setLoading(true);

    const response = await TradeInAdvertService.fetchTradeInAdvert(id);

    if ('data' in response) {
      this.setTradeInAdvert(this.serialize(response.data.trade_in_advert));
    }

    this.setLoading(false);
  }

  async createTradeInAdvert(tradeInAdvert: FormData) {
    return await TradeInAdvertService.createTradeInAdvert(tradeInAdvert);
  }

  async updateFiles(tradeInAdvertId: string, files: FormData) {
    const response = await TradeInAdvertService.updateFiles(tradeInAdvertId, files);

    if ('data' in response) {
      this.setTradeInAdvert(this.serialize(response.data.trade_in_advert));
    }
  }

  async updatePhotoDescription(advertId: string, photoId: number, description: string) {
    await TradeInAdvertService.updatePhotoDescription(advertId, photoId, description);
  }

  async updateMainPhoto(advertId: string, photoId: number) {
    await TradeInAdvertService.updateMainPhoto(advertId, photoId);
  }

  async deletePhoto(tradeInAdvertId: string, photoId: number) {
    const response = await TradeInAdvertService.deletePhoto(tradeInAdvertId, photoId);
    return response.status;
  }
}

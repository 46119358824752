import makeRequest from '@/api/makeRequest';
import { IHousingIssues } from '@/types/entities/IHousingIssues';

class HousingIssueService {
  createHousingIssue(data: IHousingIssues, needAuth: boolean) {
    return makeRequest<void>({
      url: 'api/housing_issues',
      method: 'post',
      data: data,
      authToken: needAuth,
    });
  }

  fetchHousingIssueCount() {
    return makeRequest<{ count: number; count_without_duplicates: number }>({
      url: 'api/home_housing_issues',
    });
  }
}

export default new HousingIssueService();

import clsx from 'clsx';
import { usePathname } from 'next/navigation';
import Link from 'next/link';

import { staticLinks } from '@/config/routingLinks';

import styles from './Footer.module.scss';

import IcLogo from '@/components/Icons/general/Iclogo';
import IcVk from '@/components/Icons/general/IcVk';
import IcTg from '@/components/Icons/general/IcTg';
import socSupportImg from 'assets/icons/general/ic_social-support.png';
import IcGosuslugi from '@/components/Icons/IcGosuslugi';
const Footer = () => {
  const footerNavLinks = [
    {
      title: 'Аренда',
      link: staticLinks.adverts + '?deal_type=Аренда&rent_type=Длительно',
    },
    {
      title: 'Продажа',
      link: staticLinks.adverts + '?deal_type=Продажа',
    },
    { title: 'Ипотека', link: staticLinks.mortgage },
    { title: 'Об агентстве', link: staticLinks.aboutCompany },
    { title: 'Вопросы', link: staticLinks.questions },
    { title: 'Контакты', link: staticLinks.contacts },
  ];

  const pathmane = usePathname();
  const links = [staticLinks.mapAdverts, staticLinks.mapExternalAdverts];

  const footerStyles: string = clsx({
    [styles.footer]: true,
    [styles.footerVisibility]: links.includes(pathmane),
  });

  const scrollToTop = () => window.scrollTo(0, 0);

  return (
    <footer className={footerStyles}>
      <div className={styles.footerContainer}>
        <div className={styles.footerNav}>
          <div className={styles.navigation}>
            <ul>
              {footerNavLinks.map((item) => {
                return (
                  <li key={item.title} onClick={scrollToTop}>
                    <Link href={item.link}>{item.title}</Link>
                  </li>
                );
              })}
            </ul>
            <div>
              <a href='https://mfc.admsakhalin.ru/' target='_blank' rel='noreferrer'>
                <IcGosuslugi />
              </a>
              <a href='https://csp.admsakhalin.ru/' target='_blank' rel='noreferrer'>
                <img src={socSupportImg.src} alt='' />
              </a>
            </div>
          </div>
          <div className={styles.contacts}>
            <div>
              <a href={'mailto:sakhipoteka@mail.ru'} className={styles.email}>
                sakhipoteka@mail.ru
              </a>
              <a href={'tel:+7 (4242) 312-532'} className={styles.phoneNumber}>
                8 (4242) 312-532
              </a>
            </div>

            <div className={styles.govLinks}>
              <a href='https://mfc.admsakhalin.ru/' target='_blank' rel='noreferrer'>
                <IcGosuslugi />
              </a>
              <a href='https://csp.admsakhalin.ru/' target='_blank' rel='noreferrer'>
                <img src={socSupportImg.src} alt='' />
              </a>
            </div>

            <div className={styles.socials}>
              <a href='https://vk.com/sakhipoteka' target='_blank' rel='noreferrer'>
                <IcVk />
              </a>
              <a href='https://t.me/sakhipoteka' target='_blank' rel='noreferrer'>
                <IcTg />
              </a>
            </div>
          </div>
        </div>
        <div className={styles.footerInfo}>
          <IcLogo />
          <p>
            <span>© 2024, АО «СиА»</span>
            <br />
            <Link href={staticLinks.dataPolicy}>Политика конфиденциальности</Link>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import { useEffect } from 'react';
import { ToastContainer, Zoom, toast } from 'react-toastify';
import { useRouter } from 'next/router';

import HomeApplicationMessage from './components/HomeApplicationMessage/HomeApplicationMessage';

import 'react-toastify/dist/ReactToastify.css';
import './ToastMessage.module.scss';
import Socials from './components/Socials/Socials';
import { staticLinks } from '@/config/routingLinks';

const ToastMessage = () => {
  const router = useRouter();

  const homeApplicationMessage = () =>
    toast(<HomeApplicationMessage />, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: false,
      closeOnClick: false,
      draggable: false,
      closeButton: false,
      transition: Zoom,
      toastId: 0,
    });

  const socialsMessage = () =>
    toast(<Socials />, {
      position: toast.POSITION.BOTTOM_LEFT,
      autoClose: false,
      closeOnClick: false,
      draggable: false,
      closeButton: false,
      transition: Zoom,
      toastId: 1,
    });

  useEffect(() => {
    homeApplicationMessage();
  }, []);

  useEffect(() => {
    if (router.pathname === staticLinks.main && !toast.isActive(1)) {
      socialsMessage();
    }
  }, [router.pathname]);

  return <ToastContainer newestOnTop />;
};

export default ToastMessage;

import { FC } from 'react';

import { TIcon } from '../TIcon/TIcon';

const IcHomeSuitcase: FC<TIcon> = ({ className, onClick }) => {
  return (
    <svg
      className={className}
      onClick={onClick}
      width='48'
      height='48'
      viewBox='0 0 48 48'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_5789_20171)'>
        <path
          d='M12 38H18H30H36V20L24 11L12 20V38ZM8 42V18L24 6L40 18V42H26H22H8Z'
          fill='#53B374'
        />
        <path d='M25 14L44 -2V38H25V14Z' fill='white' />
        <g clipPath='url(#clip1_5789_20171)'>
          <path
            d='M24.85 34V34.15H25H43H43.15V34V18.7895V18.6395H43H37.75V14V13.85H37.6H30.4H30.25V14V18.6395H25H24.85V18.7895V34ZM36.7 29.3605H36.85V29.2105V27.4658H41.05V31.9553H26.95V27.4658H31.15V29.2105V29.3605H31.3H36.7ZM31.3 23.3763H31.15V23.5263V25.2711H26.95V20.8342H41.05V25.2711H36.85V23.5263V23.3763H36.7H31.3ZM35.65 16.0447V18.6395H32.35V16.0447H35.65ZM34.75 25.5711V27.1658H33.25V25.5711H34.75Z'
            fill='#53B374'
            stroke='#53B374'
            strokeWidth='0.3'
          />
          <path d='M27 21H40.9996V31.8086H27V21Z' fill='white' />
        </g>
      </g>
      <defs>
        <clipPath id='clip0_5789_20171'>
          <rect width='48' height='48' fill='white' />
        </clipPath>
        <clipPath id='clip1_5789_20171'>
          <rect width='20' height='21' fill='white' transform='translate(25 14)' />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IcHomeSuitcase;
